import { apiRequest } from 'utils/axios';

/**
 * 유저 Access 토큰 비활성화
 */
export const disableAllAccessTokenAPI = async (email) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/user/disableAllAccessToken`,
      data: { email }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 유저 생성 API
 *
 * @param {string} email 유저 이메일
 * @param {string} name 유저 이름
 * @param {string} birthday 유저 생년월일 (1900-01-01)
 */
export const addUserCreateAPI = async (email, name, birthday) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/user/create`,
      data: { email, name, birthday }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 유저 밸런스 추가 API
 */
export const addUserBalanceAPI = async (email, currency, balance, bonus) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/user/addBalance`,
      data: { email, currency, balance, bonus }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 유저 정보 조회 API
 *
 * @param {string} email 유저 이메일
 */
export const getUserInfoAPI = async (email) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/user?email=${email}`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}


/**
 * OTP 생성 API
 */
export const addGenerateOtpAPI = async (email, name) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/user/generateOTP`,
      data: { email, name }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * OTP 체크 API
 */
export const addCheckOtpAPI = async (email, token) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/user/checkOTP`,
      data: { email, token }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * OTP 활성화 유무 API
 */
export const hasOtpAPI = async (email) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/user/hasOTP`,
      data: { email }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * OTP disable API
 */
export const deleteOtpAPI = async (email, token) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/user/deleteOTP`,
      data: { email, token }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 마케팅 구독 유무 API
 * @param {string} email 유저 이메일
 */
export const getMarketingSubscribedAPI = async (email) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/user/marketing?email=${email}`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 마케팅 구독 활성화 API
 * @param {string} email 유저 이메일
 */
export const addSubscribedAPI = async (email, active) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/user/marketing`,
      data: { email, active }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 최근 입금 토큰 리스트 API
 *
 * @param {string} email 유저 이메일
 */
export const getLastDepositListAPI = async (email) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/user/lastDepositList?email=${email}`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 최근 출금 토큰 리스트 API
 *
 * @param {string} email 유저 이메일
 */
export const getLastWithdrawListAPI = async (email) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/user/lastWithdrawList?email=${email}`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 히스토리 리스트 API
 *
 * @param {string} email 유저 이메일
 */
export const getGameHistoryAPI = async (email, page, limit) => {
  try {
    let url = `/user/gameHistory?email=${email}`
    if (page) {
      url += `&page=${page}`;
    }
    if (limit) {
      url += `&limit=${limit}`;
    }
    const result = await apiRequest({
      method: 'get',
      url,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 이메일 중복체크 API
 * @param {string} email 유저 이메일
 */
export const checkEmailAPI = async (email) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/user/checkEmail?email=${email}`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 이름 중복체크 API
 * @param {string} name 유저 이름
 */
export const checkNameAPI = async (name) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/user/checkName?name=${name}`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 유저 티어 관련 API
 * @param {string} email 유저 이메일
 */
export const getUserBetTotalAPI = async (email) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/user/betTotal?email=${email}`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 채팅 가능 유무 (디파짓 베팅 유무) API
 * @param {string} email 유저 이메일
 */
export const getUserChatableAPI = async (email) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/user/chatable?email=${email}`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * Game Statistics 리스트 조회 API
 */
export const getGameStatisticsAPI = async (email, startDate, endDate) => {
  try {
    let url = `/user/gameStatistics?email=${email}`;
    if (startDate) {
      url += `&startDate=${startDate}`;
    }
    if (endDate) {
      url += `&endDate=${endDate}`;
    }
    const result = await apiRequest({
      method: 'get',
      url,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * Vip 리워드 리스트 API
 * @param {string} email 유저 이메일
 */
export const getVipClubRewardAPI = async (email) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/user/vipClubReward?email=${email}`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * Vip 클레임 API
 */
export const vipClubClaimAPI = async (email) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/user/vipClubClaim`,
      data: { email }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 자체관리 회원가입 API
 */
export const addManagedUserAPI = async (name, email, password, code, addrbook, birthday) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/user/managedUser`,
      data: { name, email, password, code, addrbook, birthday }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 자체관리 이메일인지 아마존 관리 이메일인지 확인하는 API
 * 0 = 아마존
 * 1 = 자체관리
 */
export const hasManagedUserAPI = async (email) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/user/hasManagedUser?email=${email}`
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 자체관리 이메일 로그인 API (has에서 통과된 이메일 패스워드로 login할때)
 */
export const loginManagedUserAPI = async (email, password) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/user/validateManagedUser`,
      data: { email, password }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}