import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectModal, closeModal, openModal } from 'store/modules/components';
import { checkEmailAPI, checkNameAPI } from 'api/user';
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/base/Button';
import CheckBox from 'components/ui/CheckBox';
import Dialog from '@mui/material/Dialog';
import BaseButton from 'components/ui/button/BaseButton';
import FormInput from 'components/ui/input/FormInput';


function validateUserName(value) {
  if (!value) return;
  const regex = /^.{3,14}$/;
  if (!regex.test(value)) {
    return "component.modal.register.name.errMsg.default"
  }
}

function validateEmail(value) {
  if (!value) return;
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  if (!regex.test(value)) {
    return "component.modal.register.email.errMsg.invalid" // 이메일 형식이 아닙니다.
  }
}

function validatePassword(isManagedRegisterData, password) {
  if (!password) return;
  let regex;
  if (isManagedRegisterData) {
    regex = /^.{4,}$/;
    if (!regex.test(password)) {
      switch (true) {
        case password?.length < 4:
          return "component.modal.register.password.errMsg.4length"; // 패스워드는 최소 4자 이상이어야 합니다.
        default:
          return "component.modal.register.password.errMsg.default"; // 패스워드가 규칙에 맞지 않습니다.
      }
    }
  } else {
    regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    if (!regex.test(password)) {
      switch (true) {
        case password?.length < 8:
          return "component.modal.register.password.errMsg.8length"; // 패스워드는 최소 8자 이상이어야 합니다.
        case !/(?=.*[a-z])/.test(password):
          return "component.modal.register.password.errMsg.lowercasePresence"; // 소문자가 적어도 하나 이상 포함되어야 합니다.
        case !/(?=.*[A-Z])/.test(password):
          return "component.modal.register.password.errMsg.uppercasePresence"; // 대문자가 적어도 하나 이상 포함되어야 합니다.
        case !/(?=.*\d)/.test(password):
          return "component.modal.register.password.errMsg.numberPresence"; // 숫자가 적어도 하나 이상 포함되어야 합니다.
        case !/(?=.*[!@#$%^&*])/.test(password):
          return "component.modal.register.password.errMsg.specialCharacterPresence"; // 특수문자가 적어도 하나 이상 포함되어야 합니다.
        default:
          return "component.modal.register.password.errMsg.default"; // 패스워드가 규칙에 맞지 않습니다.
      }
    }
  }
  return;
}

export default function RegisterModal() {
  const { t } = useTranslation();
  const { modalType, isOpen, data } = useSelector(selectModal);
  const isManagedRegisterData = useSelector(state => { return state?.user.managedRegisterData; });
  const dispatch = useDispatch();
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [password1, setPassword1] = useState(null);
  const [password2, setPassword2] = useState(null);
  // const [redeemCode, setRedeemCode] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [ageCheck, setAgeCheck] = useState(false);

  const signInModalOpen = () => {
    dispatch(openModal({modalType: "SignIn"}));
  };

  const handleClose = () => {
    dispatch(closeModal());
  };

  const checkEmail = async (value) => {
    const check = validateEmail(value);
    if (check || !value) return;
    try {
      const res = await checkEmailAPI(value);
      if (res.count-0 > 0) {
        setEmailError("component.modal.register.email.errMsg.overlap")
      }
    } catch (e) {
      console.log('checkEmailAPI e:', e);
    }
  };

  const checkName = async (value) => {
    const check = validateUserName(value);
    if (check || !value) return;
    try {
      const res = await checkNameAPI(value);
      if (res.count-0 > 0) {
        setNameError("component.modal.register.name.errMsg.overlap")
      }
    } catch (e) {
      console.log('checkEmailAPI e:', e);
    }
  };


  const emailInput = {
    id: 'register-email',
    placeholder: 'component.modal.register.email.placeholder',
    label: 'component.modal.register.email.label',
    value: email,
    setValue: setEmail,
    isError: emailError ?? (email?.length > 3 && validateEmail(email)),
    handleFocus: () => {
      setEmailError(null);
    },
    handleBlur: () => checkEmail(email),
    isDisabled: !!isManagedRegisterData?.email
  }
  const nameInput = {
    id: 'register-username',
    placeholder: 'component.modal.register.name.placeholder',
    label: 'component.modal.register.name.label',
    msg: nameError || validateUserName(name) ? null : 'component.modal.register.name.errMsg.default',
    value: name,
    setValue: setName,
    isError: nameError ?? validateUserName(name),
    handleFocus: () => {
      setNameError(null);
    },
    handleBlur: () => checkName(name)
  }
  const password1Input = {
    id: 'register-password1',
    placeholder: 'component.modal.register.password.placeholder',
    label: 'component.modal.register.password.label',
    value: password1,
    setValue: setPassword1,
    isPassword: true,
    isError: validatePassword(isManagedRegisterData?.email, password1)
  }
  const password2Input = {
    id: 'register-password2',
    placeholder: 'component.modal.register.password.placeholder',
    label: 'component.modal.register.password.label',
    value: password2,
    setValue: setPassword2,
    isPassword: true,
    isError: (password2?.length > 3 && password1 !== password2) ? "component.modal.register.password.errMsg.match" : null
  }
  // const redeemCodeInput = {
  //   id: 'register-redeem-code',
  //   label: 'Code (Optional)',
  //   value: redeemCode,
  //   setValue: setRedeemCode
  // }
  const checkBoxInfo = {
    label: 'component.modal.register.ageCheck.label',
    isRequired: true,
    checked: ageCheck,
    setChecked: setAgeCheck
  }

  useEffect(() => {
    if (isManagedRegisterData?.email) {
      setEmail(isManagedRegisterData.email)
      checkEmail(isManagedRegisterData.email)
    }
    if (isManagedRegisterData?.name) {
      setName(isManagedRegisterData.name)
      checkName(isManagedRegisterData.name)
    }
    if (data) {
      if(data.username) setEmail(data.username)
      if(data.password) {
        setPassword1(data.password)
        setPassword2(data.password)
      }
      // if(data.redeemCode) setRedeemCode(data.redeemCode)
      if(data.options.userAttributes.name) setName(data.options.userAttributes.name)
      // if(data.option.birthday) {
      //   // console.log("birthday", data.option.birthday)
      //   const [year, month, day] = data.option.birthday.split('-');
      //   console.log("year, month, day", year, month, day)
      //   setBirthDay({ year: year, month: month, day: day })
      // }
    }
  }, [])

  const registerActive = !email || !password1 || !password2 || !name || nameError !== null || validateUserName(name) || emailError !== null || (email?.length > 3 && validateEmail(email)) || !ageCheck || validatePassword(password1) || password1 !== password2;

  const modalToSendData = () => {
    if (registerActive) {
      return;
    }
    const data = {
      username: email,
      password: password2,
      // redeemCode: redeemCode,
      options: {
        userAttributes: {
          email: email,
          name: name,
        }
      }
    }
    dispatch(openModal({modalType: "InformationCheck", data}));
  }

  return (
    <Dialog
      open={modalType === "Register" && isOpen}
      onClose={handleClose}
      className="base-dialog-wrapper small"
    >
      <div className="base-dialog-banner-wrapper">
        <div className="base-dialog-inner">
          <div className="base-dialog-header">
            <p className="base-dialog-header__title">{t('component.modal.register.title')}</p>
            <button onClick={handleClose} className="close-btn"></button>
          </div>
          <div className="base-dialog-body">
            <div className="register-form-wrapper">
              <div className="register-form-inner">
                <FormInput {...emailInput} />
                <FormInput {...nameInput} />
                <div className="password-wrapper">
                  <div className="password-inner">
                    <FormInput {...password1Input} />
                    <FormInput {...password2Input} />
                  </div>
                  <span className="form-input-msg">{t('component.modal.register.password.infoMsg', { length: isManagedRegisterData?.email ? "4" : "8" })}</span>
                </div>
                <div className="date-select-wrapper">
                  <CheckBox {...checkBoxInfo} />
                </div>
                {/* <FormInput {...redeemCodeInput} /> */}
              </div>
              <div className="register-form-submit">
                <BaseButton isDisabled={registerActive}
                onClick={modalToSendData} color="primary" label="commonDesc.register" />
              </div>
            </div>
            <div className="signin-guide-wrapper">
              <p>{t('component.modal.register.accountInfo')}</p>
              <Button onClick={signInModalOpen} className="text-btn primary">{t('component.modal.register.signNow')}</Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}