
import { useTranslation } from 'react-i18next'
import SymbolImg from 'components/item/SymbolImg';

export default function RecentList({list, title, setSelect, balanceList}) {
  const { t } = useTranslation();
  const changeSymbol = (item) => {
    const symbol = balanceList?.find(element => element.symbol === item.symbol);
    if (symbol) setSelect(symbol)
  }
  return (
    <div className="recent-wrapper">
      <p className="box-title white">{t(title)}: </p>
      {list.length ?
      <ul className="recent-inner">
        {list.map((item, index) => {
          return <li className="recent-item" key={index} onClick={() => changeSymbol(item)}>
            <SymbolImg symbol={item?.symbol} className="symbol-image" />
            <p className="symbol-name">{item?.symbol}</p>
          </li>
        })}
      </ul> : <p className="none">-</p>}
  </div>
  )
}