import { useState, useEffect } from "react";
import { setCookie, getCookie } from 'utils/cookie';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next'
import LogoImg from 'assets/Logo/logo_image.png'
import BaseButton from 'components/ui/button/BaseButton';
import CheckBox from 'components/ui/CheckBox';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export default function InstallPrompt() {
  const sessionLoading = useSelector(state => { return state?.user.sessionLoading; });
  const { t } = useTranslation();
  const [isShown, setIsShown] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [checked, setChecked] = useState(false);

  const checkBoxInfo = {
    label: 'component.modal.install.checkLabel',
    isRequired: true,
    checked,
    setChecked
  }


  useEffect(() => {
    if (!isMobile) return; // 모바일 환경 아닐 시
    const hideUntil = getCookie('hideInstallUntil');
    if (hideUntil && new Date().getTime() < parseInt(hideUntil)) return;
    const isDeviceIOS = /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream; // IOS 환경일 시
    setIsIOS(isDeviceIOS);

    const installHandler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsShown(true);
    };
    if (isDeviceIOS) {
      setIsShown(true);
    }
    window.addEventListener('beforeinstallprompt', installHandler);

    // if (isIOS && window.clientInformation.standalone) {
    //   alert("웹앱이 홈 화면에 추가되었습니다.");
    // } else if (isIOS && !window.clientInformation.standalone) {
    //   alert("웹앱이 홈 화면에 추가되지 않았습니다.");
    // }
    return () => {
      window.removeEventListener('beforeinstallprompt', installHandler);
    };
  }, []);

  const installApp = async () => {
    setIsShown(false);
    if (!deferredPrompt) {
      return;
    }
    deferredPrompt.prompt();
    const { outcome } = await deferredPrompt.userChoice;
    /*
      outcome return value
      dismissed 실패
      accepted 성공
    */
    clearPrompt();
  };

  const clearPrompt = () => {
    if (checked) {
      const days = 7 * 24 // 7일 24시간
      const time = Date.now() + days * 60 * 60 * 1000;
      setCookie('hideInstallUntil', time, days);
    }
    if (!isIOS) {
      setDeferredPrompt(null);
    }
    setIsShown(false);
  };

  if ((!isIOS && !isShown) || !isMobile || (isIOS && window.clientInformation.standalone)) {
    return null;
  }

  return (
    <Dialog
      open={!sessionLoading && isShown}
      onClose={clearPrompt}
      className="base-dialog-wrapper install-small"
    >
      <div className="base-dialog-inner">
        <div className="base-dialog-header">
          <p className="base-dialog-header__title medium">{t('component.modal.install.title')}</p>
          <button onClick={clearPrompt} className="close-btn"></button>
        </div>
        <div className="base-dialog-banner-body appInstall">
          <div className="appInstall__inner">
            <img className="logo" src={LogoImg} alt="ace casino logo img" />
            <div className="install-info">
              <p className="install-info__desc" dangerouslySetInnerHTML={{ __html: t(`component.modal.install.${isIOS ? 'notPrompt' : 'prompt'}`) }}></p>
              <CheckBox {...checkBoxInfo} />
              {!isIOS ? <BaseButton color="primary" onClick={installApp} label="component.modal.install.installBtn" /> : null}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}