
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en/translation.json";
import ko from "./ko/translation.json";
import es from "./es/translation.json";
import cn from "./cn/translation.json";
import jp from "./jp/translation.json";
import { getCookie } from 'utils/cookie';

const getPublic = async () => {
  const data = await fetch(`${process.env.PUBLIC_URL}/lang.json`).then(r => r.json())
  return data;
}

let lang;
lang = await getPublic()

const userLanguage = (()=>{
  let path = window.location?.pathname?.split("/");
  if (path[1] === 'en') {
    return "en";
  } else if (path[1] === 'ko') {
    return "ko";
  } else if (path[1] === 'cn') {
    return "cn";
  } else if (path[1] === 'es') {
    return "es";
  } else if (path[1] === 'jp') {
    return "jp";
  }
  return getCookie('userCountry') || lang;
})()
const resources = {
  en: {
    translation: en
  },
  ko: {
    translation: ko
  },
  es: {
    translation: es
  },
  cn: {
    translation: cn
  },
  jp: {
    translation: jp
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: userLanguage, // 기본 설정 언어
    fallbackLng: 'en', // 번역 파일에서 찾을 수 없는 경우 기본 언어
    debug: false,
    defaultNS: 'translation',
    ns: 'translation',
    interpolation: {
      escapeValue: true
    }
    // keySeparator: false,
    // interpolation: {
    //   escapeValue: false,
    //   alwaysFormat: true,
    //   format(value, format, lng) {
    //     if (format === 'uppercase') return value.toUpperCase();
    //     // interpolation 되는 값이 number로 들어올 때, 언어 설정에 맞는 locale string으로 변환해서 반환
    //     if (typeof value === 'number') return value.toLocaleString(lng);
    //     return value;
    //   },
    // },
  });

export default i18n;