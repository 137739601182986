import { Button } from '@mui/base/Button';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next'

export default function IconButton(props) {
  const { t } = useTranslation();
  const { color, label, onClick, size, type, isDisabled, iconName, isLoading } = props;
  return (
    <Button onClick={onClick} disabled={isDisabled || isLoading} className={`base-btn icon-btn ${!isLoading && iconName ? iconName : ''} ${color ? color : ''} ${size ? size : ''} ${type ? type : ''}`}>
      <p className={`${isLoading ? "hidden" : ""}`}>{t(label)}</p>
      {isLoading && <div className="loading-wrap"><CircularProgress size={20} color="inherit" /></div>}
    </Button>
  )
}