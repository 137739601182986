import { useState } from 'react';
import { useTranslation } from 'react-i18next'
import BaseButton from 'components/ui/button/BaseButton';
import TelegramImg from 'assets/Content/telegram.svg'
import ChangellyImg from 'assets/Content/changelly.svg'
import Dialog from '@mui/material/Dialog';
import LogoImg from 'assets/Logo/logo_image.png'

function SoonModal({ open, handleClose }) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="base-dialog-wrapper small"
    >
      <div className="base-dialog-inner bottom-p-32 m-bottom-p-24">
        <div className="base-dialog-header">
          <button onClick={handleClose} className="close-btn"></button>
        </div>
        <div className="base-dialog-body soon">
          <div className="soon-inner">
            <img src={LogoImg} alt="ace casino logo img" />
            <p>{t('commonMsg.soon')}</p>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default function BuyCryptoLayout() {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const comingModalOpen = () => {
    setModalOpen(true);
  };

  const modalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="buycrypto-layout-wrapper">
        <div className="buycrypto-layout-body">
          <div className="box-layout">
            <div className="box-layout__content">
              <img src={TelegramImg} alt="Telegram Logo Img" />
              <div className="info">
                <p>{t('component.modal.wallet.buyCrypto.usdtPurchaseConsultation.title')}</p>
                <p>{t('component.modal.wallet.buyCrypto.usdtPurchaseConsultation.info')}</p>
              </div>
            </div>
            <BaseButton onClick={() => window.open("https://t.me/ace_usdtKR")} color="primary" label="Click" size="small" />
          </div>
          <div className="box-layout">
            <div className="box-layout__content">
              <img src={ChangellyImg} alt="Telegram Logo Img" />
              <div className="info">
                <p>{t('component.modal.wallet.buyCrypto.usdtDirectPurchase.title')}</p>
                <p>{t('component.modal.wallet.buyCrypto.usdtDirectPurchase.info')}</p>
              </div>
            </div>
            <BaseButton onClick={comingModalOpen} color="primary" label="Click" size="small" />
          </div>
        </div>
      </div>
      <SoonModal open={modalOpen} handleClose={modalClose} />
    </>
  )
}


