import { useSelector } from 'react-redux';
import { selectModal } from 'store/modules/components';

import WalletModal from 'components/modal/wallet/Wallet';
import RegisterModal from 'components/modal/Register';
import SignInModal from 'components/modal/SignIn';
// import TwoFactorModal from 'components/modal/TwoFactor';
import InformationCheckModal from 'components/modal/InformationCheck';
// import VaultModal from 'components/modal/vault/Vault';
import VipModal from 'components/modal/vip/Vip';
import LanguageModal from 'components/modal/Language';
import NoticeModal from 'components/modal/Notice';
import RedeemModal from 'components/modal/Redeem';

const MODAL_TYPES = {
  Register: "Register",
  SignIn: "SignIn",
  Wallet: "Wallet",
  // Vault: "Vault",
  Vip: "Vip",
  InformationCheck: "InformationCheck",
  // TwoFactor: "TwoFactor",
  Language: "Language",
  Notice: "Notice",
  Redeem: "Redeem"
};

const MODAL_COMPONENTS = [
  {
    type: MODAL_TYPES.Register,
    component: <RegisterModal />
  },
  {
    type: MODAL_TYPES.SignIn,
    component: <SignInModal />
  },
  {
    type: MODAL_TYPES.Wallet,
    component: <WalletModal />
  },
  {
    type: MODAL_TYPES.InformationCheck,
    component: <InformationCheckModal />
  },
  // {
  //   type: MODAL_TYPES.Vault,
  //   component: <VaultModal />
  // },
  {
    type: MODAL_TYPES.Vip,
    component: <VipModal />
  },
  // {
  //   type: MODAL_TYPES.TwoFactor,
  //   component: <TwoFactorModal />
  // },
  {
    type: MODAL_TYPES.Language,
    component: <LanguageModal />
  },
  {
    type: MODAL_TYPES.Notice,
    component: <NoticeModal />
  },
  {
    type: MODAL_TYPES.Redeem,
    component: <RedeemModal />
  },
];

export default function GlobalModal() {
  const { modalType, isOpen, twoFactorModal } = useSelector(selectModal);
  if (!isOpen) return;

  const findModal = MODAL_COMPONENTS.find((modal) => {
    return modal.type === modalType;
  });

  const renderModal = () => {
    return findModal.component;
  };
  return (
    <>
      {renderModal()}
      {/* {twoFactorModal && <TwoFactorModal />} */}
    </>
  );
}