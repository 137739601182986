import { Button } from '@mui/base/Button';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next'

export default function BaseButton(props) {
  const { t } = useTranslation();
  const { color, label, onClick, size, type, isDisabled, isLoading, subLabel } = props;
  return (
    <Button type={type} onClick={onClick} disabled={isDisabled || false}
    className={`base-btn ${color ? color : ''} ${size ? size : ''} ${type ? type : ''}`}>
      {label ? <p className={`${isLoading ? "hidden" : ""}`}>{t(label)}</p> :
      <p className={`${isLoading ? "hidden" : ""}`}>{subLabel}</p>}
      {isLoading && <div className="loading-wrap"><CircularProgress size={20} color="inherit" /></div>}
    </Button>
  )
}