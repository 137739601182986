

export default function KycAml() {
  return (
    <div>
      <h3>AML Policy of ACE Gaming Limited,</h3>
      <p>AML Policy of ACE Gaming Limited,</p>
      <p>Last updated: December 15, 2023</p>
      <p>For Cash Deposits and Cash Withdraws. </p>
      <p>AMLI Anti-Money-Laundering policy of <span className="link">www.acecasino.io</span>. </p>
      <p>Introduction: www.acecasino.io is operated by ACE Gaming Co., Ltd. having its office at Av. Pastor Diaz, Provincia de Puntarenas, Jaco, 61101, Costa Rica Company Registration number 3-102-885129</p>
      <p>Objective of the AML Policy: We seek to offer the highest security to all of our users and customers on <span className="link">www.acecasino.io</span> for that a two-step account verification is done in order to insure the identity of our customers. The reason behind this is to prove that the details of the person registered are correct and the deposit methods used are not stolen or being used by someone else, which is to create the general framework for the fight against money laundering. We also take into accord that depending on the nationality and origin, the way of payment and for withdrawing different safety measurements must be taken. </p>
      <p><span className="link">www.acecasino.io</span> also puts reasonable measures in place to control and limit ML risk, including dedicating the appropriate means.  </p>
      <p><span className="link">www.acecasino.io</span> is committed to high standards of anti-money laundering (AML) according to the EU guidelines, compliance and requires management & employees to enforce these standards in preventing the use of its services for money laundering purposes. </p>
      <p>The AML program of <span className="link">www.acecasino.io</span> is designed to be compliant with : </p>
      <p>EU : “Directive 2015/849 of the European Parliament and of The Council of 20 May 2015 on the prevention of the use of the financial system for the purposes of money laundering” </p>
      <p>EU : “Regulation 2015/847 on information accompanying transfers of funds” </p>
      <p>EU : Various regulations imposing sanctions or restrictive measures against persons and embargo on certain goods and technology, including all dual-use goods </p>
      <p>BE : “Law of 18 September 2017 on the prevention of money laundering limitation of the use of cash</p>
      <p className="strong">Definition of money laundering: </p>
      <ul>
        <li><p>Money Laundering is understood as: </p></li>
        <li><p>The conversion or transfer of property, especially money,  knowing that such property is derived from criminal activity or from taking part in such activity, for the purpose of concealing or disguising the illegal origin of the property or of helping any person who is involved in the commission of such an activity to evade the legal consequences of that person's or companies action; </p></li>
        <li><p>The concealment or disguise of the true nature, source, location, disposition, movement, rights with respect to, or ownership of, property, knowing that such property is derived from criminal activity or from an act of participation in such an activity; </p></li>
        <li><p>The acquisition, possession or use of property, knowing, at the time of receipt, that such property was derived from criminal activity or from assisting in such an activity; </p></li>
        <li><p>Participation in, association to commit, attempts to commit and aiding, abetting, facilitating and counselling the commission of any of the actions referred to in points before.  </p></li>
      </ul>
      <p>Money laundering shall be regarded as such even when the activities which generated the property to be laundered were carried out in the territory of another Member State or in that of a third country. </p>
      <p className="strong">Organization of the AML for <span className="link">www.acecasino.io</span>:</p>
      <p>In accordance with the AML legislation, <span className="link">www.acecasino.io</span> has appointed the “highest level” for the prevention of M L: The full management of ACE Gaming Co., Ltd. are in charge.  </p>
      <p>Furthermore, an AMLCO (Anti Money Laundering Compliance Officer) is in charge of the enforcement of the AML policy and procedures within the System. </p>
      <p>The AMLCO is placed under the direct responsibility of the general Management: </p>
      <p className="strong">AML policy changes and implementation requirements: </p>
      <p>Each major change of <span className="link">www.acecasino.io</span> AML policy is subject to be approval by the general management of ACE Gaming Co., Ltd. and the Anti money laundering compliance officer. </p>
      <p className="strong">Two step Verification:  </p>
      <p>Step one verification: </p>
      <p>Step one verification must be done by every user and customer to withdraw. Regarding of the choice of payment, the amount of payment, the amount of withdraw, the choice of withdraw and nationality of the user/customer step one verification must be done first. Step one verification is a document that must be filled out by the user/customer himself. Following information’s must be filled in: first name, second name, date of birth, country of usual residence, gender and full address. </p>
      <p>Step two verification: </p>
      <p>Step two verification must be done by every user which deposit over 10,000$ (ten thousand Dollar) or withdraws over 10,000$ (ten thousand Dollar). Until step two verification is done the withdraw, tip or deposit will be hold. Step to verification will lead the user or customer to a subpage where he must send in his ID. The user/customer must make a picture of his ID. While a paperclip with a six-digit random generated number is next to his ID: Only an official ID may be used for ID verification, depending on the country the variety of accepted IDs may be different. In addition, user/customer might be asked for a source of wealth. There will also be an electronic check if the filled in Data from the step one verification is correct. The electronic check will check via two different databanks to insure the given information’s matches with the filled document and the name from the ID: If the electronic test fails or is not possible the user/customer is required to send in a conformation of his current resident. A certificate of registration by the government or a similar document is required. </p>
      <p className="strong">Customer identification and verification (KYC) </p>
      <p>The formal identification of customers on entry into commercial relations is a vital element, both for the regulations relating to money laundering and for the KYC policy. </p>
      <p>This identification relies on the following fundamental principles: </p>
      <p>A copy of your passport, ID card or driving license, each shown alongside a handwritten note mentioning six random generated numbers. Also, a second picture with the face of the user/customer is required. The user/customer may blur out every information, besides date of birth, nationality, gender, first name, second name and the picture. To secure their privacy. </p>
      <p>Please note that all four corners of the ID have to be visible in the same image and all details has to be clearly readable besides the named above. We might ask for all details if necessary. </p>
      <p>An employee may do additional checks if necessary, based on the situation.</p>
      <p className="strong">Proof of Address: </p>
      <p>Proof of address will be done via to different electronic checks, which use two different databases. If an electronic test fails, the user/customer has the option to make a manually proof. </p>
      <p>A recent utility bill sent to your registered address, issued within the last 3 months or an official document made by the government that proofs your state of residence. </p>
      <p>To make the approval process as speedy as possible, please make sure the document is sent with a clear resolution where all four corners of the document is visible, and all text is readable.</p>
      <p>For example: An electricity bill, water bill, bank statement or any governmental post addressed to you.</p>
      <p>An employee may do additional checks if necessary, based on the situation.</p>
      <p className="strong">Source of funds</p>
      <p>If a player deposits over a five thousand euro there is a process of understandings the source of wealth (SOW)</p>
      <p>Examples of SOW are:</p>
      <ul>
        <li><p>Ownership of business</p></li>
        <li><p>Employment</p></li>
        <li><p>Inheritance </p></li>
        <li><p>Investment </p></li>
        <li><p>Family </p></li>
      </ul>
      <p>It is critical that the origin and legitimacy of that wealth is clearly understood. If this is not possible an employee may ask for an additional document or prove.  </p>
      <p>The account will be frozen if the same user deposits either this amount in one go or multiple transactions which amount to this. An email will be sent to them manually to go through the above and an information on the website itself. </p>
      <p><span className="link">www.acecasino.io</span> also asks for a bank wire/credit card to further insure the Identity of the user/customer. It also gives additional information about the financial situation of the user/customer. </p>
      <p className="strong">Basic document for step one: </p>
      <p>The basic document will be accessible via the setting page on <span className="link">www.acecasino.io</span>. Every user has to fill out the following information’s: </p>
      <ul>
        <li><p>First name </p></li>
        <li><p>Second name</p></li>
        <li><p>Nationality</p></li>
        <li><p>Gender </p></li>
        <li><p>Date of Birth </p></li>
      </ul>
      <p>The document will be saved and created by an AI, an employee may do additional checks if necessary based on the situation.</p>
      <p className="strong">Risk management: </p>
      <p>In order to deal with the different risks and different states of wealth in different regions on the earth <span className="link">www.acecasino.io</span> will categorize every nation in three different regions of risk.</p>
      <p>Region one: Low risk and Region two: Medium risk:</p>
      <p>For every nation from the region one and two the two-step verification is done as described earlier. </p>
      <p>Region three: High risk:</p>
      <p>Regions of high risks will be banned. High risk regions will be regularly updated to keep up with the changing environment of a fast-changing world.</p>
      <p className="strong">Additional measurements. </p>
      <p>In addition, an AI which is overseen by the AML compliance officer will look for any unusual behaviour and report it right away to employee of <span className="link">www.acecasino.io</span>. </p>
      <p>According to a risk based few and general experience the human employees will recheck all checks which are done bevor by the AI or other employees and may redo or do additional checks according to the situation. </p>
      <p>In addition, a data Scientist supported by modern, electronic, analytic systems will look for unusual behaviour like: Depositing and withdrawing without longer Betting sessions. Attempts to use a different Bank account to for Deposit and Withdraw, nationality changes, currency changes, behaviour and activity changes as well as checks, if an account is used by it´s original owner. </p>
      <p>Also a User has to use the same method for Withdraw as he used for Deposit, for the amount of the initial Deposit to prevent any Money Laundering. </p>
      <p>Enterprise-wide risk assessment </p>
      <p>As part of its risk-based approach, <span className="link">www.acecasino.io</span> has conducted an AML “Enterprise-wide risk assessment” (EWRA) to identify and understand risks specific to <span className="link">www.acecasino.io</span> and its business lines. The AML risk policy is determined after identifying and documenting the risks inherent to its business lines such as the services the website offers. The Users to whom services are offered, transactions performed by these Users, delivery channels used by the bank, the geographic locations of the bank’s operations, customers and transactions and other qualitative and emerging risks. </p>
      <p>The identification of AML risk categories is based on <span className="link">www.acecasino.io</span> understanding of regulatory requirements, regulatory expectations and industry guidance. Additional safety measures are taken to take care of the additional risks the world wide web brings with it. </p>
      <p>The EWRA is yearly reassessed. </p>
      <p className="strong">Ongoing transaction monitoring </p>
      <p>AML-Compliance ensures that an “ongoing transaction monitoring” is conducted to detect transactions which are unusual or suspicious compared to the customer profile.This transaction monitoring is conducted on two levels: </p>
      <p>1) The first Line of Control: </p>
      <p><span className="link">www.acecasino.io</span> works solely with trusted Payment Service Providers whom all have effective AML policies in place as to prevent the large majority of suspicious deposits onto <span className="link">www.acecasino.io</span> from taking place without proper execution of KYC procedures onto the potential customer. </p>
      <p>2) The second Line of Control:</p>
      <p><span className="link">www.acecasino.io</span> makes its network aware so that any contact with the customer or player or authorized representative must give rise to the exercise of due diligence on transactions on the account concerned. In particular these include: </p>
      <ul>
        <li><p>Requests for the execution of financial transactions on the account; </p></li>
        <li><p>Requests in relation to means of payment or services on the account; </p></li>
      </ul>
      <p>Also, the three-step verification with adjusted risk management should provide all necessary information’s about all costumers of <span className="link">www.acecasino.io</span> at all time. </p>
      <p>Also, all transaction must be overseen by employees over watched by the AML compliance officer who is over watched by the general management. </p>
      <p>The specific transactions submitted to the customer support manager, possibly through their Compliance Manager must also be subject to due diligence. </p>
      <p>Determination of the unusual nature of one or more transactions essentially depends on a subjective assessment, in relation to the knowledge of the customer (KYC), their financial behaviour and the transaction counterparty. </p>
      <p>These checks will be done by an automated System, while an Employee crosschecks them for additional security. </p>
      <p>The transactions observed on customer accounts for which it is difficult to gain a proper understanding of the lawful activities and origin of funds must therefore rapidly be considered atypical (as they are not directly justifiable). </p>
      <p>Any <span className="link">www.acecasino.io</span> staff member must inform the AML division of any atypical transactions which they observe and cannot attribute to a lawful activity or source of income known of the customer. </p>
      <p>3) The third Line of Control: </p>
      <p>As a last line of defence against AML <span className="link">www.acecasino.io</span> will do manually checks on all suspicious and higher risk users in order to fully prevent money laundering. </p>
      <p>If fraud or Money Laundering is found the authorities will be informed. </p>
      <p className="strong">Reporting of Suspicious transactions on <span className="link">www.acecasino.io</span></p>
      <p>In its internal procedures, <span className="link">www.acecasino.io</span> describes in precise terms, for the attention of its staff members, when it is necessary to report and how to proceed with such reporting. </p>
      <p>Reports of atypical transactions are analysed within the AML team in accordance with the precise methodology fully described in the internal procedures. </p>
      <p>Depending on the result of this examination and on the basis of the information gathered, the AML team: </p>
      <p>will decide whether it is necessary or not to send a report to the FIU, in accordance with the legal obligations provided in the Law of 18 September 2017; </p>
      <p>will decide whether or not it is necessary to terminate the business relations with the customer. </p>
      <p className="strong">Procedures </p>
      <p>The AML rules, including minimum KYC standards will be translated into operational guidance or procedures that are available on the Intranet site of <span className="link">www.acecasino.io</span>.</p>
      <p className="strong">Record keeping </p>
      <p>Records of data obtained for the purpose of identification must be kept for at least ten years after the business relationship has ended. </p>
      <p>Records of all transaction data must be kept for at least ten years following the carrying-out of the transactions or the end of the business relationship. </p>
      <p>These data will be safely, encrypted stored offline and online. </p>
      <p className="strong">Training:</p>
      <p><span className="link">www.acecasino.io</span> human employees will make manual controls on a risk based approve for which they get special training. </p>
      <p>The training and awareness program is reflected by its usage: </p>
      <ul>
        <li><p>A mandatory AML training program in accordance with the latest regulatory evolutions, for all in touch with finances </p></li>
        <li><p>Academic AML learning sessions for all new employees </p></li>
      </ul>
      <p>The content of this training program has to be established in accordance with the kind of business the trainees are working for and the posts they hold. These sessions are given by an AML-specialist working in ACE GAMING Limited  AML team.</p>
      <p className="strong">Auditing </p>
      <p>Internal audit regularly establishes missions and reports about AML activities. </p>
      <p className="strong">Data Security:</p>
      <p>All data given by any user/customer will be kept secure, will not be sold or given to anyone else. Only if forced by law, or to prevent money laundering data may be shared with the AML-authority of the affected state. </p>
      <p><span className="link">www.acecasino.io</span> will follow all guidelines and rules of the data protection directive (officially Directive 95/46/EC) </p>
      <p></p>
      <p className="strong">Contact us:</p>
      <p>If you have any questions about our AML and KYC Policy, please contact us:</p>
      <ul>
        <li><p>By email: support@acecasino.io</p></li>
      </ul>
      <p></p>
      <p>If you have any complains about our AML and KYC Policy or about the checks done on your Account and your Person, please contact us: </p>
      <p></p>
      <ul>
        <li><p>By email: business@acecasino.io</p></li>
      </ul>
    </div>
  )
}