import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { movePath } from 'utils/movePath';
import Select from 'components/ui/select/Select'
import Policy from './Policy'
import KycAml from './KycAml'
import TermsOfConditions from './TermsOfConditions'
import ReponsibleGambling from './ReponsibleGambling'
import { useTranslation } from 'react-i18next'

const asideMenu = [
  {
    title: "Policy",
    component: <Policy />,
    id: "policy",
  },
  {
    title: "KYC/AML",
    component: <KycAml />,
    id: "kyc-aml",
  },
  {
    title: "Terms of Conditions",
    component: <TermsOfConditions />,
    id: "termsOfConditions",
  },
  {
    title: "Reponsible Gambling",
    component: <ReponsibleGambling />,
    id: "reponsibleGambling",
  },
]

export default function Policies() {
  const { t } = useTranslation();
  const { section } = useParams();
  const current = asideMenu.find(item => item.id === section);
  const [state, setState] = useState(current || asideMenu[0]);
  const nav = useNavigate();
  const lang = useSelector(state => {
    return state?.user?.language;
  });

  useEffect(() => {
    if (current) return;
    let pathName = `/policies/${state.id}`;
    movePath(nav, lang, pathName)
  }, [state]);

  const selectData = { selectList: asideMenu, id: "policies", select: state, setSelect: setState };

  return (
    <div className="policies-wrapper section-wrapper">
      <div className="section-wrapper__header">
        <p className="policies">{t('gnb.policies')}</p>
      </div>
      <div className="policies-inner">
        <div className="policy-viewer-header">
          <p className="state-title">{state.title}</p>
          <Select {...selectData} />
        </div>
        <div className="policy-viewer-body">
          <div className={`policy-viewer-body__inner`}>
            {asideMenu.find(item => item.id === state.id)?.component}
          </div>
        </div>
      </div>
    </div>
  )
}