import React, { useState, useEffect } from 'react';
// import SearchInput from 'components/ui/input/SearchInput'
import SwitchUi from 'components/ui/Switch'
// import { useDispatch } from 'react-redux';
import { toNumberFormat } from 'utils/toNumberFormat';
// import { setCurrentSymbol } from 'store/modules/user';
import { OutlinedInput } from '@mui/material';
import { useTranslation } from 'react-i18next'
import SymbolImg from 'components/item/SymbolImg';

function CurrencyRowItem(props) {
  const { balance, symbol, chain_name, total, bonus } = props;
  // const dispatch = useDispatch();

  // const changeSelectedCurrency = () => {
  //   const currentSymbol = {
  //     symbol: symbol,
  //     chain_name: chain_name,
  //     balance: balance,
  //     bonus: bonus,
  //     total: total,
  //   }
  //   props.close()
  //   dispatch(setCurrentSymbol(currentSymbol))
  // };

  return (
    <div className="currency-row-item">
    {/* <div className="currency-row-item" onClick={changeSelectedCurrency}> */}
      <div className="amounts">
        <p>{total ? toNumberFormat(total, 8) : '0.00000000'}</p>
        <span>{`${balance ? toNumberFormat(balance, 2) : '0.00'}+${bonus ? toNumberFormat(bonus, 2) : '0.00'}(Bonus)`}</span>
      </div>
      <div className="symbols">
        <SymbolImg symbol={symbol} className="symbols__img" />
        <p className="symbols__name">{symbol}</p>
      </div>
    </div>
  )
}

export default function CurrencyTooltip(props) {
  const { UserCurrentList: balanceList } = props;
  const [checked, setChecked] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const [list, setList] = useState([]);
  const { t } = useTranslation();
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    let filteredList;
    if (keyword?.length) {
      filteredList = balanceList.filter(data =>
        data.symbol.toLowerCase().includes(keyword.toLowerCase())
      );
    } else {
      filteredList = balanceList;
    }
    if (checked) {
      filteredList = filteredList.filter(item => item.total - 0 > 0);
    }
    setList(filteredList);
  }, [keyword, balanceList, checked])

  const onChange = (e)=> {
    const inputValue = e.target.value;
    setKeyword(inputValue)
  }

  const label = { inputProps: { 'aria-label': 'Hide zero amount' } };
  return (
    <div className="c-tooltip">
      <div className="c-tooltip__inner">
        <div className="tooltip-item-header">
          <div className="search-input-wrapper">
            <OutlinedInput
              className="currency-search"
              autoComplete="off"
              placeholder={t('component.search.placeholder')}
              name="currency-search"
              id="currency-search"
              type={"text"}
              value={keyword || ''}
              onChange={onChange}
              // onKeyDown={(ev) => {
              //   if (ev.key === 'Enter' && isFunction) isFunction()
              // }}
              startAdornment={<div className="search-icon"></div>}
              />
          </div>
        </div>
        <div className="tooltip-item-body">
          {list.length ? list.map((item, index) => {
            return <CurrencyRowItem {...item} key={index} {...props} />
          }) : <div className="not"><p>{t('component.currencyCoin.notAvailable')}</p></div>}
        </div>
        <div className="tooltip-item-footer">
          <SwitchUi checked={checked} handleChange={handleChange} {...label} />
          <p>{t('component.currencyCoin.hideZero')}</p>
        </div>
      </div>
    </div>
  )
}