
export const getTier = (value) => {
  if (value <= 5000) {
    return "Iron";
  } else if (value < 50000) {
    return "Bronze";
  } else if (value < 100000) {
    return "Silver";
  } else if (value < 500000) {
    return "Gold";
  } else if (value < 3000000) {
    return "Platinum";
  } else if (value < 20000000) {
    return "Emerald";
  } else {
    return "Diamond";
  }
}

export const getNextTier = (value) => {
  const tiers = ["Iron", "Bronze", "Silver", "Gold", "Platinum", "Emerald", "Diamond"];
  const currentTier = getTier(value);
  const currentIndex = tiers.indexOf(currentTier);

  if (currentIndex !== -1 && currentIndex < tiers.length - 1) {
    return tiers[currentIndex + 1];
  } else {
    return null;
  }
}

export const  getNextTierInfo = (currentTier, amount) => {
  const tiers = [
    { name: "Iron", value: 0 },
    { name: "Bronze", value: 5000 },
    { name: "Silver", value: 50000 },
    { name: "Gold", value: 100000 },
    { name: "Platinum", value: 500000 },
    { name: "Emerald", value: 3000000 },
    { name: "Diamond", value: 20000000 }
  ];
  const currentTierIndex = tiers.findIndex(tier => tier.name === currentTier);
  if (!tiers[currentTierIndex+1]) {
    return 100
  }

  let gap = tiers[currentTierIndex+1]?.value - tiers[currentTierIndex]?.value
  let pin = amount - tiers[currentTierIndex]?.value
  // console.log(tiers[currentTierIndex+1].value, tiers[currentTierIndex].value, amount, pin)

  // pin : gap = x : 100
  let percentageToNextTier = (pin * 100) / gap
  // console.log("percentageToNextTier", percentageToNextTier)
  return {percentageToNextTier}

}