import { useSelector } from 'react-redux';
import { getNextTierInfo } from 'utils/userTier';
import { toNumberFormat } from 'utils/toNumberFormat';
import LinearPropgress from 'components/ui/LinearPropgress'
import { useTranslation } from 'react-i18next'

export default function ProgressLayout() {
  const { t } = useTranslation();
  const betTotal = useSelector(state => { return state?.user?.betTotal; });
  const { percentageToNextTier } = getNextTierInfo(betTotal.currentTier, betTotal.wagerAmount)
  return (
    <div className="progress-layout-wrapper">
      <div className="progress-layout-header">
        <img className="progress-layout-header__img" src={require(`assets/Content/modal-vip-bg.png`)} alt="vip bg" />
        <div className="user-grade">
          <div className="user-grade__header">
            <div className="user-state-grade">
              <p dangerouslySetInnerHTML={{ __html: t('component.modal.vip.progress.current',
              {currentTier: betTotal?.currentTier.toUpperCase(), wagerAmount: toNumberFormat(betTotal.wagerAmount, 2)}) }}></p>
            </div>
            <p className="user-state-value">{(percentageToNextTier && !isNaN(percentageToNextTier)) ? percentageToNextTier.toFixed(2) : "0"}%</p>
          </div>
          <LinearPropgress value={percentageToNextTier} />
          <div className="user-grade__step">
            <div className="row-item">
              {betTotal?.currentTier && <img src={require(`assets/Grade/${betTotal?.currentTier}.png`)} alt={`${betTotal?.currentTier} icon`} />}
              <p>{t(`tier.${betTotal?.currentTier}`)}</p>
            </div>
            <div className="row-item">
              <img src={require(`assets/Grade/${betTotal?.nextTier}.png`)} alt={`${betTotal?.nextTier} icon`} />
              <p>{t(`tier.${betTotal?.nextTier}`)}</p>
            </div>
          </div>
        </div>
        <p className="info-msg">{t('component.modal.vip.progress.info')}</p>
      </div>
      <div className="progress-layout-body">
        <ul className="propgress-list propgress-list-header">
          <li>{t('component.modal.vip.progress.listHead.tier')}</li>
          <li>{t('component.modal.vip.progress.listHead.wagerAmount')}</li>
          <li>{t('component.modal.vip.progress.listHead.cashback')}</li>
        </ul>
        <ul className="propgress-list propgress-list-body">
          <li className="grade-symbol Iron">{t(`tier.Iron`)}</li>
          <li>-</li>
          <li>1.0%</li>
        </ul>
        <ul className="propgress-list propgress-list-body">
          <li className="grade-symbol Bronze">{t(`tier.Bronze`)}</li>
          <li>$5,000</li>
          <li>5.0%</li>
        </ul>
        <ul className="propgress-list propgress-list-body">
          <li className="grade-symbol Silver">{t(`tier.Silver`)}</li>
          <li>$50,000</li>
          <li>6.0%</li>
        </ul>
        <ul className="propgress-list propgress-list-body">
          <li className="grade-symbol Gold">{t(`tier.Gold`)}</li>
          <li>$100,000</li>
          <li>7.0%</li>
        </ul>
        <ul className="propgress-list propgress-list-body">
          <li className="grade-symbol Platinum">{t(`tier.Platinum`)}</li>
          <li>$500,000</li>
          <li>8.0%</li>
        </ul>
        <ul className="propgress-list propgress-list-body">
          <li className="grade-symbol Emerald">{t(`tier.Emerald`)}</li>
          <li>$3,000,000</li>
          <li>9.0%</li>
        </ul>
        <ul className="propgress-list propgress-list-body">
          <li className="grade-symbol Diamond">{t(`tier.Diamond`)}</li>
          <li>$20,000,000</li>
          <li>10.0%</li>
        </ul>
      </div>
      <div className="caution">
        <span>VIP Cashback is calculated from the house edge</span>
      </div>
    </div>
  )
}