import { apiRequest } from 'utils/axios';

/**
 * 프로모션 리스트 조회 API
 *
 * @param {string} email 유저 이메일
 */
export const getPromotionListAPI = async (email) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/promotion?email=${email}`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 활성화 되있는 프로모션 유무 API
 */
export const getIsActivatePromotionAPI = async (email) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/promotion/isactive?email=${email}`
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 프로모션 활성화 API
 */
export const activatePromotionAPI = async (email, title) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/promotion/activate`,
      data: { email, title }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 진행중인 프로모션 취소 API
 */
export const cancelPromotionAPI = async (email, title) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/promotion/cencal`,
      data: { email, title }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * test 프로모션 추가 API
 */
export const addPromotionAPI = async (email, title, currency, amount) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/promotion/testadd`,
      data: { email, title, currency, amount }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * test 프로모션 삭제 API
 */
export const deletePromotionAPI = async (email, title) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/promotion/testdelete`,
      data: { email, title }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}
