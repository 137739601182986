import { createSlice } from '@reduxjs/toolkit';
import { setCookie, getCookie } from 'utils/cookie';

const getPublic = async () => {
  const data = await fetch(`${process.env.PUBLIC_URL}/lang.json`).then(r => r.json())
  return data;
}

let lang;
lang = await getPublic()

const initialState = {
  userInfo: null,
  isLoggedIn: null,
  jwtToken: null,
  idToken: null,
  hasOtp: null,
  language: '',
  sessionLoading: false,
  currentSymbol: null,
  betTotal: {
    wagerAmount: null,
    currentTier: "",
    nextTier: "",
  },
  managedRegisterData: {
    email: "",
    name: "",
    addrbook: ""
  }
};
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      setCookie('userCountry', action.payload);
      state.language = action.payload;
    },
    setSessionLoading: (state, action) => {
      state.sessionLoading = action.payload;
    },
    getLanguage: (state) => {
      let path = window.location?.pathname?.split("/");
      if (path[1] === 'en') {
        state.language = "en";
        return;
      } else if (path[1] === 'ko') {
        state.language = "ko";
        return;
      } else if (path[1] === 'cn') {
        state.language = "cn";
        return;
      } else if (path[1] === 'es') {
        state.language = "es";
        return;
      } else if (path[1] === 'jp') {
        state.language = "jp";
        return;
      }
      const userLanguage = getCookie('userCountry') || lang;
      state.language = userLanguage;
    },
    setUserInfo: (state, action) => {
      if (action.payload) {
        state.userInfo = action.payload;
        state.isLoggedIn = true;
      }
    },
    setUserBet: (state, action) => {
      const { wagerAmount, currentTier, nextTier } = action.payload;
      state.betTotal = { wagerAmount, currentTier, nextTier };
    },
    setCurrentSymbol: (state, action) => {
      state.currentSymbol = action.payload;
      localStorage.setItem('current_symbol', JSON.stringify(state.currentSymbol));
    },
    setUserHasOtp: (state, action) => {
      if (action.payload) state.hasOtp = action.payload;
    },
    setUserToken: (state, action) => {
      const { jwtToken, idToken } = action.payload;
      state.jwtToken = jwtToken;
      state.idToken = idToken;
    },
    clearUserInfo:(state) => {
      state.userInfo = null
      state.isLoggedIn = null
      state.jwtToken = null;
      state.idToken = null;
      state.hasOtp = null;
      state.currentSymbol = null;
      state.betTotal = {
        wagerAmount: null,
        currentTier: "",
        nextTier: "",
      }
    },
    setManagedRegisterData: (state, action) => {
      const { email, name, addrbook } = action.payload;
      state.managedRegisterData = {
        email,
        name,
        addrbook
      }
    },
  },
});

export default userSlice.reducer;
export const user = (state) => state.user;
export const { setUserInfo,
  clearUserInfo,
  setLanguage,
  setUserBet,
  setCurrentSymbol,
  setUserToken,
  setUserHasOtp,
  getLanguage,
  setSessionLoading,
  setManagedRegisterData,
 } = userSlice.actions;