import React, { useState, useEffect } from 'react';
import SymbolSelect from 'components/ui/select/SymbolSelect';
import CheckBox from 'components/ui/CheckBox';
import CopyItem from 'components/ui/CopyItem';
import CautionItem from 'components/ui/CautionItem';
import RecentList from './RecentList';
import { useSelector } from 'react-redux';
import { getDepositAddressAPI } from 'api/wallet'
import { getLastDepositListAPI } from 'api/user'
import { QRCodeCanvas } from 'qrcode.react';
import { useTranslation } from 'react-i18next'
import useUserInfoUpdate from 'hooks/useUserInfoUpdate'


function SelecCoin({ select, setSelect, checked, setChecked, allBalanceList }) {
  const { t } = useTranslation();
  const data = { select, setSelect, selectList: allBalanceList, id: "coin-select-deposit" }
  const checkBoxInfo = {
    checked,
    setChecked
  }

  return (
    <div className="select-wrapper">
      <div className="select-inner">
        <p className="box-title">{t('component.modal.selectCoin')}</p>
        {select ? <SymbolSelect {...data} /> : null}
      </div>
      <div className="select-inner">
        <p className="box-title">{t('component.modal.network')}</p>
        <div className="selected-network">
          <p>{select?.chain_name === "ETHEREUM" ? "ERC20" : select?.chain_name}</p>
          {select ? <CheckBox {...checkBoxInfo} /> : null}
        </div>
      </div>
    </div>
  )
}

export default function DepositLayout() {
  const { t } = useTranslation();
  const [init, setInit] = useState(false);
  const [checked, setChecked] = useState(false);
  const [address, setAddress] = useState('');
  const [select, setSelect] = useState(null);
  const [allBalanceList, setAllBalanceList] = useState([]);
  const [recentTokenList, setRecentTokenList] = useState([]);
  const userInfo = useSelector(state => { return state?.user?.userInfo; });
  const { email: userEmail, balance } = userInfo;
  const currentSymbol = useSelector(state => { return state?.user?.currentSymbol; });
  const isMobile = useSelector(state => { return state?.ui?.screen.tuchDevice; });
  const { updateUserBalance, sortUserBalance } = useUserInfoUpdate();

  useEffect(() => {
    updateUserBalance()
    getLastDepositList();
  }, [])

  useEffect(() => {
    const list = sortUserBalance('total');
    setAllBalanceList(list);

    if (currentSymbol && balance.length) {
      const currentSelect = list.find(item => item.symbol === currentSymbol.symbol);
      setSelect(currentSelect)
    }
  }, [balance])

  useEffect(() => {
    if (!init) {
      setInit(true)
      return;
    }
    setChecked(false);
    setAddress('');
    if (select?.symbol) {
      getDepositAddress()
    }
  }, [select?.symbol])


  const getDepositAddress = async () => {
    /* currency가 변경될때마다 호출되게끔 변경 */
    try {
      const res = await getDepositAddressAPI(userEmail, select?.symbol)
      setAddress(res)
    } catch (e) {
      console.log("getDepositAddressAPI e: ", e);
    }
  }

  const getLastDepositList = async () => {
    try {
      const res = await getLastDepositListAPI(userEmail)
      if (res) {
        const filterList= res?.filter(currency => currency.symbol !== "USD");
        setRecentTokenList(filterList)
      }
    } catch (e) {
      console.log("getLastDepositListAPI e: ", e);
    }
  }

  return (
    <div className="deposit-layout-wrapper">
      <div className="deposit-layout-header">
        {allBalanceList?.length ? <SelecCoin select={select} setSelect={setSelect} checked={checked} setChecked={setChecked} allBalanceList={allBalanceList} />: null}
        <RecentList list={recentTokenList} title="component.modal.wallet.deposit.recent" setSelect={setSelect} balanceList={allBalanceList} />
      </div>
      <div className="deposit-layout-body">
        <p className="box-title">{t('component.modal.wallet.deposit.isAddres', { network: select?.chain_name === "ETHEREUM" ? "ERC20" : select?.chain_name })}</p>
        <div className="QRcode-wrapper">
          {address && checked ? <>
            {/* <p className="box-title">Under Maintenance</p> */}
            <div className="QRcode-inner">
              <QRCodeCanvas value={address}
                className="QR-image"
                includeMargin
                size={isMobile ? 92 : 160} />
            </div>
            {address ? <CopyItem address={address} styled="start" /> : "..."}
          </> : <p className="box-title">{t('component.modal.wallet.deposit.checkNetwork')}</p>}
        </div>
      </div>
      <CautionItem styled="row" desc="component.modal.wallet.deposit.caution" />
    </div>
  )
}