
export const GNB_ITEMS = {
  profile: [
    {
      name: 'Live Casino',
      pathName: '/casino',
      icon: 'casino',
      isLoggedIn: false,
    },
    {
      name: 'Slot',
      pathName: '/slot',
      icon: 'slot',
      isLoggedIn: false,
    },
    {
      name: 'Wallet',
      component: 'Wallet',
      icon: 'wallet',
      isLoggedIn: true,
    },
    // {
    //   name: 'Vault',
    //   component: 'Vault',
    //   icon: 'vault',
    //   isLoggedIn: true,
    // },
    // {
    //   name: 'VIP Club',
    //   component: 'Vip',
    //   icon: 'vip',
    //   isLoggedIn: true,
    // },
    // {
    //   name: 'Affiliate',
    //   pathName: '/affiliate/overview',
    //   icon: 'affiliate',
    //   isLoggedIn: true,
    // },
    {
      name: 'Statistics',
      pathName: '/statistics',
      icon: 'statistics',
      isLoggedIn: true,
    },
    {
      name: 'History',
      pathName: '/history',
      icon: 'history',
      isLoggedIn: true,
    },
    {
      name: 'Promotion',
      pathName: '/promotion',
      icon: 'promotion',
      isLoggedIn: true,
    },
  ],
  more: [
    {
      name: 'Settings',
      pathName: '/settings/preference',
      icon: 'settings',
      isLoggedIn: true,
    },
    {
      name: 'Policies',
      pathName: '/policies/policy',
      icon: 'policies',
      isLoggedIn: false,
    },
    {
      name: 'Language',
      component: 'Language',
      icon: 'language',
      isLoggedIn: false,
    },
    // {
    //   name: 'Live Support',
    //   pathName: '#',
    //   icon: 'livesupport',
    //   isLoggedIn: true,
    // },
  ],
}