import { useTranslation } from 'react-i18next'
import LogoImg from 'assets/Logo/logo_image.png'

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="bg-wrap layer-01">
      <div className="footer">
        <div className="footer__head">
          <img src={LogoImg} alt="ace casino logo img" />
          <p>{t('footer.reserved')}</p>
        </div>
        <ul className="footer__body">
          <li><p>{t('footer.info.1')}</p></li>
          <li><p dangerouslySetInnerHTML={{ __html: t('footer.info.2') }}></p></li>
          <li>
            <div className="email-wrapper">
              <p>{t('footer.support')} <a href="mailto:support@acecasino.io">support@acecasino.io</a></p>
              <span>|</span>
              <p>{t('footer.partner')} <a href="mailto:business@acecasino.io">business@acecasino.io</a></p>
            </div>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer;