import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getGameStatisticsAPI } from 'api/user';
import dayjs from 'dayjs';
import BaseButton from 'components/ui/button/BaseButton';
import { toNumberFormat } from 'utils/toNumberFormat';
import { useTranslation } from 'react-i18next'
import SymbolImg from 'components/item/SymbolImg';
// import Select from 'components/ui/select/Select'

const datePickerFormat = "YYYY-MM-DD";
const datePickerUtils = {
    format: datePickerFormat,
    parse: (value) => dayjs(value, datePickerFormat, true).toDate(),
    // You can add other utils as needed, such as `isValid`, etc.
};

function transformString(inputString) {
  return inputString.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}

function formatToYYYYMMDD(value) {
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

  if (regex.test(value)) {
    return value;
  } else {
    const dateObj = new Date(value);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
}

// const selectList = [
//   "Today",
//   "1 Week",
//   "1 Month",
//   "6 Months",
//   "1 Year",
// ]

const tableHead = [
  "pages.statistics.tableHead.cryptos",
  "pages.statistics.tableHead.bets",
  "pages.statistics.tableHead.wins",
  "pages.statistics.tableHead.loses",
  "pages.statistics.tableHead.deposit",
  "pages.statistics.tableHead.wagered",
  "pages.statistics.tableHead.totalWinning",
  "pages.statistics.tableHead.totalLosing",
]

export default function Statistics() {
  const user = useSelector(state => { return state?.user; });
  const { userInfo } = user;
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([])
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 30);
  const [startDate, setStartDate] = useState(dayjs(currentDate));
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const { t } = useTranslation();

  // const [select, setSelect] = useState(selectList[0]);
  // const selectData = { selectList, id: "statistics", select, setSelect };
  // const tuchDevice = useSelector(state => { return state?.ui?.screen.tuchDevice; });

  const getList = async () => {
    if (!userInfo?.email) return;
    if (loading) return;
    setLoading(true);
    const email = userInfo?.email;
    const start = formatToYYYYMMDD(startDate)
    const end = formatToYYYYMMDD(endDate)
    try {
      const res = await getGameStatisticsAPI(email, start, end)
      setList(res);
    } catch (e) {
      console.log("e: ", e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getList()
  }, [])

  return (
    <div className="table-section-wrapper section-wrapper">
      <div className="section-wrapper__header select-rows">
        <p className="statistics">{t('pages.statistics.title')}</p>
        {/* {tuchDevice && <Select {...selectData} />} */}
      </div>
      <div className="table-section-inner">
        <div className="table-header">
          <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={datePickerUtils}>
            <div className="base-date-picker-wrapper">
              <DatePicker className="base-date-picker" label={t('commonDesc.startDate')} format="YYYY / MM / DD" name="startDate"
                slotProps={{ textField: { size: 'small' } }}
                value={startDate} onChange={(newValue) => {setStartDate(newValue);}} />
              <DatePicker className="base-date-picker" label={t('commonDesc.endDate')} format="YYYY / MM / DD" name="endDate"
                slotProps={{ textField: { size: 'small' } }}
                value={endDate} onChange={(newValue) => {setEndDate(newValue);}} />
            </div>
          </LocalizationProvider>
          <BaseButton onClick={() => getList()} color="white" label="commonDesc.setBtn" size="small" />
          {/* {!tuchDevice && <Select {...selectData} />} */}
        </div>
        <div className="table-container statistics">
          <table className="table-inner">
            <colgroup>
              {tableHead.map((head, index) => {
                return <col key={index} className={transformString(head)} />;
              })}
            </colgroup>
            <thead className="table-inner__head">
              <tr>
              {tableHead.map((head, index) => {
                return <th key={index} className={head}>{t(head)}</th>
              })}
              </tr>
            </thead>
            <tbody className="table-inner__body">
              {list?.length ? list.map((body, index) => {
                return <tr key={index}>
                  <td className="symbol">
                    <div className="symbol__inner">
                      <SymbolImg className="symbol-image" symbol={body?.symbol} />
                      <p>{body?.symbol ? body?.symbol: "-"}</p>
                    </div>
                  </td>
                  <td><p>{body?.bet_count ? toNumberFormat(body.bet_count, 2) : "-"}</p></td>
                  <td><p>{body?.win_count ? toNumberFormat(body.win_count, 2) : "-"}</p></td>
                  <td><p>{body?.los_count ? toNumberFormat(body.los_count, 2) : "-"}</p></td>
                  <td><p>{body?.deposit_amount ? toNumberFormat(body.deposit_amount, 2) : "-"}</p></td>
                  <td><p>{body?.wagered ? toNumberFormat(body.wagered, 2) : "-"}</p></td>
                  <td><p>{body?.winning ? toNumberFormat(body.winning, 2) : "-"}</p></td>
                  <td><p>{body?.losing ? toNumberFormat(body.losing, 2) : "-"}</p></td>
                </tr>
              }) : null}
            </tbody>
          </table>
          {!list?.length ?
            <div className="not-list">
              <p>{t('pages.statistics.notList')}</p>
            </div> : null}
        </div>
      </div>
    </div>
  )
}