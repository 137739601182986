import { apiRequest } from 'utils/axios';

/**
 * 캠페인 생성 API
 *
 * @param {string} email 유저 이메일
 * @param {string} name 캠페인 이름 (유저한테만 유니크한 값)
 * @param {string} code 레퍼럴 코드 (전체적으로 유니크한 값)
 * @param {string} memo
 */
export const createCampaignAPI = async (email, name, code, memo) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/affilate/createCampaign`,
      data: { email, name, code, memo }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}


/**
 * 캠페인 중복확인 체크 API
 *
 * @param {string} email 유저 이메일
 * @param {string} name 캠페인 이름 (유저한테만 유니크한 값)
 * @param {string} code 레퍼럴 코드 (전체적으로 유니크한 값)
 */
export const checkCampaignAPI = async (email, name, code) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/affilate/checkCampaign?email=${email}&name=${name}&code=${code}`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 유저 캠페인 단일 조회 API
 *
 * @param {string} email 유저 이메일
 * @param {string} code
 */
export const getCampaignAPI = async (email, code) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/affilate/getCampaign?email=${email}&code=${code}`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 유저 캠페인 리스트 조회 API
 *
 * @param {string} email 유저 이메일
 */
export const getUserCampaignListAPI = async (email) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/affilate/campaignList?email=${email}`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * Affiliate 생성 API
 *
 * @param {string} email 유저 이메일
 * @param {string} code
 * @param {string} memo
 */
export const addAffiliateAPI = async (email, code, memo) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/affilate/addAffiliate`,
      data: { email, code, memo }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * Affiliate overview 리워드 퍼센트 표기 API
 *
 * @param {string} email 유저 이메일
 */
export const getAffiliateCampaignRewardRatioAPI = async (email) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/affilate/campaignRewardRatio?email=${email}`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 유저 Affiliate 리스트 조회 API
 *
 * @param {string} email 유저 이메일
 * @param {string} name 캠페인 이름
 */
export const getUserAffiliateListAPI = async (email, name) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/affilate/affiliateList?email=${email}&name=${name}`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 유저 클래임 가능한 수량 조회 API
 *
 * @param {string} email 유저 이메일
 * @param {string} name 캠페인 code
 */
export const getClaimableInfoAPI = async (email, name) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/affilate/claimableInfo?email=${email}&name=${name}`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}
/**
 * 유저 캠패인 히트수 조회 API
 *
 * @param {string} email 유저 이메일
 * @param {string} name 캠페인 code
 */
export const getCampaignHitInfoAPI = async (email, name) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/affilate/campaignHitInfo?email=${email}&name=${name}`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 유저 캠패인 클래임 API
 *
 * @param {string} email 유저 이메일
 * @param {string} name 캠페인 name
 */
export const claimCampaignAPI = async (email, name) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/affilate/claimCampaign`,
      data: { email, name }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 상위 플레이어 여부 조회 API
 *
 * @param {string} email 유저 이메일
 */
export const getIsChildAPI = async (email) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/affilate/ischild?email=${email}`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}


 /**
 * 하위유저에게 토큰 전송하는 API
 *
 * @param {string} fromEmail 보내는 유저 이메일
 * @param {string} currency 토큰 심볼
 * @param {string} amount 값
 * @param {string} toEmail 받는 유저 이메일
 */
export const userSendTokenAPI = async (fromEmail, currency, amount, toEmail) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/affilate/sendToken`,
      data: { fromEmail, currency, amount, toEmail }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export const getSubAffiliateListAPI = async () => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/affilate/getSubAffiliateList`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export const getSubAffiliateInfoAPI = async (email) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/affilate/affilateInfo?email=${email}`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export const createSubAffiliateAPI = async (commision) => {
 try {
   const result = await apiRequest({
     method: 'post',
     url: `/affilate/createSubAffiliate`,
     data: { commision }
   });
   return result;
 } catch (e) {
   console.error(e);
   throw e;
 }
}