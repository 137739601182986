import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getSubAffiliateListAPI, createSubAffiliateAPI } from 'api/affilate'
import { movePath } from 'utils/movePath';

import Dialog from '@mui/material/Dialog';
import { CircularProgress } from '@mui/material';
import SubAffiliateTable from './SubAffiliateTable'
import BaseButton from 'components/ui/button/BaseButton';
import FormInput from 'components/ui/input/FormInput';
import CopyItem from 'components/ui/CopyItem';
import NotFound from 'pages/error/NotFound';

import NoListLogo from 'assets/Logo/logo_no_list.png';

function ConfirmModal({ open, handleClose, create, loading }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="base-dialog-wrapper content-dialog-wrapper small"
    >
      <div className="base-dialog-inner bottom-p-32">
        <div className="base-dialog-header">
          <p className="base-dialog-header__title medium">{t('pages.affiliate.subAffiliate.createModal.title')}</p>
          <button onClick={handleClose} className="close-btn"></button>
        </div>
        <div className="base-dialog-body notice">
          <div className="notice-wrapper">
            <div className="notice-wrapper__inner">
              <p>{t('pages.affiliate.subAffiliate.createModal.desc')}</p>
            </div>
            <BaseButton onClick={create} isLoading={loading} color="primary" label="pages.affiliate.subAffiliate.createSubRedeemCode.createBtn" size="large" />
          </div>
        </div>
      </div>
    </Dialog>
  );
}

const AccordionItem = (props) => {
  const { t } = useTranslation();
  const contentEl = useRef();
  const { toggle, active, item, index, detailManage } = props;
  const isMobile = useSelector(state => { return state?.ui?.screen?.viewType.mobile; });

  return (
    <div className="accordion-item">
      <ul className={`accordion-item__head`} onClick={() => toggle(index)} style={isMobile ? { cursor: 'pointer' } : {}}>
        {isMobile ? (<>
          <li className="arrow num"><p>{index + 1}</p></li>
          <li><p>{item?.status ? item.status : "-"}</p></li>
          <li><p>{item?.commision_rate ? Math.floor(item.commision_rate) : "-"}%</p></li>
        </>)
         : (<>
          <li><p>{index + 1}</p></li>
          <li>{item?.sub_code ? <CopyItem address={`${item.sub_code}`} styled="between" /> : <p>-</p>}</li>
          <li><p>{item?.status ? item.status : "-"}</p></li>
          <li><p>{item?.commision_rate ? Math.floor(item.commision_rate) : "-"}%</p></li>
          <li><p>{item?.email ? item.email : "-"}</p></li>
        </>)}
        {isMobile ? <li className={`arrow ${active === index ? 'active' : ''}`}></li> : <li><BaseButton onClick={() => detailManage(item)} isDisabled={item?.status !== "Completed"} size="small" color="danger" label="pages.affiliate.subAffiliate.manageBtn" /></li>}
      </ul>
      {(active !== null && active === index && isMobile) ?
      <div ref={contentEl} className={`accordion-item__body ${active === index ? 'show' : ''}`}
        style={active === index ? { height: "auto" } : { height: "0px" }}>
          <div className="user-inner">
            <ul className="user-inner__data">
              <li>
                <span>{t('pages.affiliate.subAffiliate.tableHead.redeemCode')}</span>
                {item?.sub_code ? <CopyItem address={item.sub_code} styled="between" /> : <p>-</p>}
              </li>
              <li>
                <span>{t('pages.affiliate.subAffiliate.tableHead.accountName')}</span>
                <p>{item?.email ? item.email : "-"}</p>
              </li>
            </ul>
            <div className="actions-btn">
              <BaseButton onClick={() => detailManage(item)} isDisabled={item?.status !== "Completed"} size="small" color="danger" label="pages.affiliate.subAffiliate.manageBtn" />
            </div>
          </div>
      </div> : null }
    </div>
  )
}

function SubAffiliateList({originRewardRatio}) {
  const nav = useNavigate();
  const { t } = useTranslation();
  const isMobile = useSelector(state => { return state?.ui?.screen?.viewType.mobile; });
  const lang = useSelector(state => { return state?.user.language; });
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [newCommision, setNewCommision] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [active, setActive] = useState(null);

  const openModalConfirm = () => {
    if (!newCommision || newCommision >= originRewardRatio) return;
    setConfirmOpen(true)
  }

  const toggle = (index) => {
    if (!isMobile) return;
    // console.log(index)
    if (active === index) setActive(null);
    else setActive(index);
  }

  const detailManage = (item) => {
    if (!item?.email || !item?.commision_rate) return;
    let pathName = `/affiliate/subAffiliate?email=${item.email}&commision=${Math.floor(item.commision_rate)}`;
    movePath(nav, lang, pathName)
    if (isMobile) {
      window.scrollTo(0, 0);
    } else {
      document.getElementById('scrollbar').scrollTo(0, 0);
    }
  }

  const setCreateSubRedeemCodeInput = {
    isEndItem: '%',
    isIntegerValue: true,
    id: 'add-sub-redeem',
    placeholder: 'pages.affiliate.subAffiliate.createSubRedeemCode.placeholder',
    label: 'pages.affiliate.subAffiliate.createSubRedeemCode.label',
    value: newCommision,
    setValue: setNewCommision,
    inputText: "number",
    isError: newCommision >= originRewardRatio && t('pages.affiliate.subAffiliate.createSubRedeemCode.errorMsg'),
  }

  const createSubAffiliate = async () => {
    if (!newCommision) return;
    setCreateLoading(true)
    const value = newCommision / 100;
    try {
      await createSubAffiliateAPI(value)
      setNewCommision(null)
      setConfirmOpen(false)
      getSubAffiliateList()
    } catch(e) {
      console.error("e: ", e)
    } finally {
      setCreateLoading(false)
    }
  }

  const getSubAffiliateList = async () => {
    setLoading(true)
    try {
      const res = await getSubAffiliateListAPI()
      if (res?.length) {
        setList(res);
      }
    } catch(e) {
      console.error("e: ", e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getSubAffiliateList()
  }, [])

  return (
    <>
      <div className="sub-affiliate">
        <div className="overview-content">
          <p className="overview-content__title">{t('pages.affiliate.subAffiliate.infoList.1')}</p>
          <div className="overview-content__inner">
            <p>{t('pages.affiliate.subAffiliate.infoList.2')}</p>
            <ul className="list">
              <li><p>{t('pages.affiliate.subAffiliate.infoList.3')}</p></li>
              <li><p>{t('pages.affiliate.subAffiliate.infoList.4')}</p></li>
            </ul>
            <div className="subText">
              <div className="subText__desc">
              <p>{t('pages.affiliate.subAffiliate.infoList.5')}</p>
              <p>{t('pages.affiliate.subAffiliate.infoList.6')}</p>
              <p>{t('pages.affiliate.subAffiliate.infoList.7')}</p>
              </div>
            </div>
            <div className="subText">
              <div className="subText__desc">
              <p>{t('pages.affiliate.subAffiliate.infoList.8')}</p>
              <p>{t('pages.affiliate.subAffiliate.infoList.9')}</p>
              <p>{t('pages.affiliate.subAffiliate.infoList.10')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="sub-affiliate-inner">
          <div className="sub-affiliate-inner__info">
            <p>{t('pages.affiliate.overview.commission')} <span>{originRewardRatio ? originRewardRatio : '-'}%</span></p>
          </div>
          <div className="sub-affiliate-inner__head">
            <FormInput labelSize="small" {...setCreateSubRedeemCodeInput} />
            <BaseButton onClick={() => openModalConfirm()} isDisabled={newCommision >= originRewardRatio} color="primary" label="pages.affiliate.subAffiliate.createSubRedeemCode.createBtn" />
          </div>
          <div className="sub-affiliate-inner__body">
            <div className="accordion-table-container">
              <div className="accordion-table-container__inner">
                <ul className="accordion-table-container__head">
                  {isMobile ? (<>
                      <li className="arrow"><p>No</p></li>
                      <li><p>{t('pages.affiliate.subAffiliate.tableHead.status')}</p></li>
                      <li><p>{t('pages.affiliate.subAffiliate.tableHead.commisionRate')}</p></li>
                    </>) : (<>
                      <li><p>No</p></li>
                      <li><p>{t('pages.affiliate.subAffiliate.tableHead.redeemCode')}</p></li>
                      <li><p>{t('pages.affiliate.subAffiliate.tableHead.status')}</p></li>
                      <li><p>{t('pages.affiliate.subAffiliate.tableHead.commisionRate')}</p></li>
                      <li><p>{t('pages.affiliate.subAffiliate.tableHead.accountName')}</p></li>
                    </>)}
                  {isMobile ? <li className="arrow"></li> : <li><p></p></li>}
                </ul>
                {loading ? <div className="accordion-table-container__loading">
                  <CircularProgress color="inherit" size={60} />
                </div>  :
                <div className="accordion-table-container__body">
                  {list?.length ? list.map((item, index) => {
                    return <AccordionItem key={index} active={active} toggle={toggle} detailManage={detailManage} item={item} index={index} />
                  }) : null}
                </div>}
                {!loading && !list?.length ? <div className="no-list"><img src={NoListLogo} alt="no list" /></div> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal open={confirmOpen} handleClose={() => setConfirmOpen(false)} create={() => createSubAffiliate()} loading={createLoading} />
    </>
  )
}

export default function SubAffiliateLayout({rewardRatio, hasSubAgents}) {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const paramEmail = searchParams.get('email');
  const paramCommision = searchParams.get('commision');

  if (!rewardRatio || hasSubAgents === null) return (
    <div className="sub-affiliate">
      <div className="sub-affiliate__loading">
        <CircularProgress color="inherit" size={70} />
      </div>
    </div>
  )

  if (hasSubAgents) {
    return <div className="sub-affiliate">
      <NotFound />
    </div>
  }

  return (
    <>
      {paramEmail ? <SubAffiliateTable email={paramEmail} commision={Math.floor(paramCommision)} originRewardRatio={rewardRatio} /> : <SubAffiliateList originRewardRatio={rewardRatio} />}
    </>
  )
}