import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { selectModal, closeModal } from 'store/modules/components';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next'
import { setLanguage } from 'store/modules/user';
import { movePath } from 'utils/movePath';
import i18n from "locales/i18n";
import { LANGUAGE_LIST as languageList } from 'constants/language'

export default function Language() {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { gameData } = location?.state || {};

  const { modalType, isOpen } = useSelector(selectModal);
  const user = useSelector(state => { return state?.user; });
  const { language } = user;
  const currentLanguage = Object.keys(languageList).find(item => language === item);
  const handleClose = () => { dispatch(closeModal()); };

  const changeLanguage = (newLang) => {
    if (language !== newLang) {
      let pathSegments = pathname?.split('/');
      if (pathSegments && pathSegments?.length > 1) {
        pathSegments.splice(1, 1);
      }
      const newPathname = pathSegments?.join('/');
      i18n.changeLanguage(newLang);
      dispatch(setLanguage(newLang));
      const pathData = gameData ? { gameData } : undefined;
      movePath(nav, newLang, newPathname, pathData);
      handleClose()
    }
  }
  return (
    <Dialog
      open={modalType === "Language" && isOpen}
      onClose={handleClose}
      className="base-dialog-wrapper select-small"
    >
      <div className="base-dialog-inner">
        <div className="base-dialog-header">
          <p className="base-dialog-header__title medium">{t('gnb.language')}</p>
          <button onClick={handleClose} className="close-btn"></button>
        </div>
        <div className="base-dialog-banner-body language">
          <ul className="select-list">
            {Object.entries(languageList).map(([key, value]) => (
              <li key={key} onClick={() => changeLanguage(key)} className={`list-item ${currentLanguage === key ? "isActive" : ""}`}>
                <p>{value}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Dialog>
  )
}