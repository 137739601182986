import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from "notistack";
import { selectModal, closeModal } from 'store/modules/components';

import Dialog from '@mui/material/Dialog';
import FormInput from 'components/ui/input/FormInput';
import BaseButton from 'components/ui/button/BaseButton';

import useAuth from 'hooks/useAuth';

export default function Redeem() {
  const { t } = useTranslation();
  const { registerRedeemCode } = useAuth();
  const dispatch = useDispatch();
  const { modalType, isOpen } = useSelector(selectModal);

  const [code, setCode] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => { dispatch(closeModal()); };

  const campaignNameInput = {
    id: 'redeem-code',
    label: 'component.modal.redeemCode.code.label',
    value: code,
    setValue: setCode,
  }

  return (
    <Dialog
      open={modalType === "Redeem" && isOpen}
      onClose={handleClose}
      className="base-dialog-wrapper content-dialog-wrapper install-small"
    >
      <div className="base-dialog-inner bottom-p-32">
        <div className="base-dialog-header">
          <p className="base-dialog-header__title medium">{t('component.modal.redeemCode.title')}</p>
          <button onClick={handleClose} className="close-btn"></button>
        </div>
        <div className="base-dialog-body campaign">
          <div className="campaign-form">
            <div className="campaign-form__inner">
              <FormInput {...campaignNameInput} />
            </div>
            <BaseButton isLoading={loading} onClick={() => registerRedeemCode(code, setLoading, handleClose)}
            isDisabled={!code} color="primary" label="component.modal.redeemCode.confirmBtn" />
          </div>
        </div>
      </div>
    </Dialog>
  )
}