import GameList from './GameList';

export default function GameSlot() {
  const data = {
    gameType: "lg",
    title: "pages.games.title.casino",
    iconName: "casino"
  }

  return (
    <>
      <GameList {...data} />
    </>
  )
}