import { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getClaimableInfoAPI, getCampaignHitInfoAPI, userSendTokenAPI } from 'api/affilate';
import { getUserCampaignListAPI } from 'api/affilate';

import NoListLogo from 'assets/Logo/logo_no_list.png';
import { toNumberFormat } from 'utils/toNumberFormat';

import useUserInfoUpdate from 'hooks/useUserInfoUpdate'

import Dialog from '@mui/material/Dialog';
import CopyItem from 'components/ui/CopyItem';
import BaseButton from 'components/ui/button/BaseButton';
import CurrencySelect from 'components/ui/select/CurrencySelect';
import FormInput from 'components/ui/input/FormInput';
import { useSnackbar } from "notistack";

function setRefLink(campaignCode) {
  const url = window.location.hostname === "www.acecasino.io" ? 'acecasino.io/en/campaign/' : 'test.acecasino.io/en/campaign/'
  const result = `${url}${campaignCode}`
  return result;
}

const detailTableHead = [
  "pages.affiliate.referredUsers.tableBody.cryptos",
  "pages.affiliate.referredUsers.tableBody.date",
  "pages.affiliate.referredUsers.tableBody.claimAmount",
  "pages.affiliate.referredUsers.tableBody.rolloverAmount",
  "pages.affiliate.referredUsers.tableBody.claimable",
  "pages.affiliate.referredUsers.tableBody.claimed",
]

function SendTokenModal({ open, handleClose, toEmail }) {
  const { t } = useTranslation();
  const { updateUserBalance, sortUserBalance } = useUserInfoUpdate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const currentSymbol = useSelector(state => { return state?.user?.currentSymbol; });
  const userInfo = useSelector(state => { return state?.user?.userInfo; });
  const { email: fromEmail, balance } = userInfo;

  const [currency, setCurrency] = useState(null);
  const [allBalanceList, setAllBalanceList] = useState([]);
  const [amount, setAmount] = useState(null);
  const [loading, setLoading] = useState(false);

  const data = { select: currency, setSelect: setCurrency, selectList: allBalanceList, id: "coin-select-deposit", color: "layer-02" }
  const amountInput = {
    id: 'sendToken-amount',
    label: 'component.modal.sendToken.amount.label',
    msg: `${t('commonDesc.balance')}: ${currency?.balance ? toNumberFormat(currency?.balance, 8) : "0.00000000"} ${currency?.symbol}`,
    isBalance: true,
    isDisabled: !currency?.balance,
    value: amount,
    setValue: setAmount,
    isMax: () => {
      if (currency?.balance) {
        setAmount(currency?.balance)
      }
    }
  }

  useEffect(() => {
    updateUserBalance()
  }, [])

  useEffect(() => {
    setAmount(null)
  }, [currency?.symbol])

  useEffect(() => {
    const list = sortUserBalance('total');
    setAllBalanceList(list);

    if (currentSymbol && balance.length) {
      const currentSelect = list.find(item => item.symbol === currentSymbol.symbol);
      setCurrency(currentSelect)
    }
  }, [balance])

  const userSendToken = async () => {
    setLoading(true)
    try {
      const res = await userSendTokenAPI(fromEmail, currency?.symbol, amount, toEmail)
      if (res.status === "success") {
        enqueueSnackbar({ msg: "component.modal.sendToken.sendSuccess", variant: "success", action: () => {closeSnackbar()} })
        handleClose()
        updateUserBalance()
      }
    } catch(e) {
      console.error('userSendTokenAPI e: ', e)
      enqueueSnackbar({ msg: e.message, variant: "error", action: () => {closeSnackbar()} })
    } finally {
      setLoading(false)
    }
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="base-dialog-wrapper content-dialog-wrapper small"
    >
      <div className="base-dialog-inner bottom-p-32">
        <div className="base-dialog-header">
          <p className="base-dialog-header__title medium">{t('component.modal.sendToken.title')}</p>
          <button onClick={handleClose} className="close-btn"></button>
        </div>
        <div className="base-dialog-body sendToken">
          <div className="send-user-form">
            <div className="select-wrapper">
              <div className="select-inner">
                <p className="box-title">{t('component.modal.sendToken.to')}</p>
                <p className="box-text">{toEmail ? toEmail : null}</p>
              </div>
              <div className="select-inner">
                <p className="box-title">{t('component.modal.selectCoin')}</p>
                {currency ? <CurrencySelect {...data} /> : null}
              </div>
            </div>
            <FormInput inputText="number" labelSize="large" {...amountInput} />
          </div>
          <BaseButton isLoading={loading} isDisabled={!fromEmail || !toEmail || amount > currency?.balance-0 || 0 >= currency?.balance-0 || !amount || amount <= 0 || loading || !currency} onClick={() => userSendToken()} size="large" color="primary" label="component.modal.sendToken.title" />
        </div>
      </div>
    </Dialog>
  );
}

const AccordionItem = (props) => {
  const { t } = useTranslation();
  const contentEl = useRef();

  const { toggle, active, item, index, rewardRatio, originRewardRatio, email } = props;
  const { campaign_name, campaign_code, wagered, commission, deposit_sum } = item;
  const [innerData, setInnerData] = useState({});
  const { total, list: innerList } = innerData;


  const getData = async () => {
    /**
     * inner data api call
     * claimInfo
     *  - claimable: true이면 클래임 버튼을 활성화
     *  - claimInfo: accordion detail list data
     */
    Promise.all([
      getClaimableInfoAPI(email, campaign_name),
      getCampaignHitInfoAPI(email, campaign_name)
    ]).then((res) => {
      const [claimInfo, hitCount] = res;
      const data = {
        total: {
          campaignHits: hitCount.toString(),
          referrals: hitCount.toString(),
          comissionRate: originRewardRatio - Number(rewardRatio),
        },
        list : claimInfo?.claimInfo ?? [],
      }
      setInnerData(data)
    })
  }
  useEffect(() => {
    if (active !== null && active === index) {
      getData()
    }
  }, [active])

  return (
    <>
      <div className="accordion-item">
        <ul className={`accordion-item__head`} onClick={() => toggle(index)}>
          <li className="name"><p>{campaign_name}</p></li>
          <li className="link"><p>{setRefLink(campaign_code)}</p></li>
          <li className="commission"><p>{deposit_sum ? toNumberFormat(deposit_sum , 6): "-"}</p></li>
          <li className="referrals"><p>{wagered ? toNumberFormat(wagered, 6): "-"}</p></li>
          <li className="totalCommission">
            {/* <SymbolImg className="symbol-image" symbol={symbol} /> */}
            <p>{commission ? toNumberFormat(commission ): "-"}</p>
          </li>
          <li className={`arrow ${active === index ? 'active' : ''}`}></li>
        </ul>
        <div ref={contentEl} className={`accordion-item__body ${active === index ? 'show' : ''}`}
          style={active === index && Object.keys(innerData)?.length ? { height: "auto" } : { height: "0px" }}>
          <div className="item-inner">
            <div className="item-inner__inner">
              <ul className="item-inner__inner--total">
                <li>
                  <span>{t('pages.affiliate.manageCampaign.tableBody.campaignHits')}</span>
                  <p>{total?.campaignHits ? total.campaignHits :""}</p>
                </li>
                <li>
                  <span>{t('pages.affiliate.manageCampaign.tableBody.referrals')}</span>
                  <p>{total?.referrals ? total.referrals : ""}</p>
                </li>
                <li>
                  <span>{t('pages.affiliate.manageCampaign.tableBody.comissionRate')}</span>
                  <p>{(total?.comissionRate != null && !isNaN(total?.comissionRate)) && total.comissionRate}%</p>
                </li>
              </ul>
              {active === index && typeof innerList !== "undefined" && innerList?.length ?
                <div className="table-section-list">
                  <div className="table-section-wrapper">
                    <div className="table-section-inner">
                      <div className="table-container item-list">
                        <table className="table-inner">
                          <colgroup>
                            {detailTableHead.map((head, index) => {
                              return <col key={index} className={`${head.includes('cryptos') ? "cryptos" : ""}`} />;
                            })}
                          </colgroup>
                          <thead className="table-inner__head">
                            <tr>
                            {detailTableHead.map((head, index) => {
                              return <th key={index} className={head}>{t(head)}</th>
                            })}
                            </tr>
                          </thead>
                          {innerList.map((item, index) => {
                            const symbol = item?.name.replace(/\(([^)]+)\)/, (match, capture) => {
                              return capture === "Ethereum" ? "(ERC20)" : capture === "Tron" ? "(TRC20)" : match;
                            });
                            return (
                              <tbody className="table-inner__body" key={index}>
                                <tr>
                                  <td><p>{item.date_claimed ? item.date_claimed : '-'}</p></td>
                                  <td><p>{item.name ? symbol : '-'}</p></td>
                                  <td><p>{item.claim_amount ? toNumberFormat(item.claim_amount, 6) : '0.00'}</p></td>
                                  <td><p>{item.rollover_amount ? toNumberFormat(item.rollover_amount, 6) : '0.00'}</p></td>
                                  <td><p>{item.claimable ? 'O' : 'X'}</p></td>
                                  <td><p>{item.claimed ? 'O' : 'X'}</p></td>
                                </tr>
                              </tbody>
                            );
                          })}
                        </table>
                      </div>
                    </div>
                  </div>
                </div> : null}
            </div>
            <div className="actions">
              <div className="actions__link">
                <span>{t('pages.affiliate.manageCampaign.refLink')}</span>
                <CopyItem address={window.location.hostname === "www.acecasino.io" ? `www.${setRefLink(campaign_code)}` : setRefLink(campaign_code)} styled="between" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function TableBody(props) {
  const { t } = useTranslation();
  const { email, commision: rewardRatio, originRewardRatio } = props;
  const [active, setActive] = useState(null);
  const [list, setList] = useState([]);

  const getData = async () => {
    try {
      const res = await getUserCampaignListAPI(email)
      setList(res)
    } catch(e) {
      console.log("e: ", e)
    }
  }

  const toggle = (index) => {
    if (active === index) setActive(null)
    else setActive(index)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <div className="campaign-content__body">
        <div className="accordion-table-container manageCampaign">
          <div className="accordion-table-container__inner">
            <ul className="accordion-table-container__head">
              <li className="name"><p>{t('pages.affiliate.manageCampaign.tableHead.name')}</p></li>
              <li className="link"><p>{t('pages.affiliate.manageCampaign.tableHead.link')}</p></li>
              <li className="commission"><p>{t('pages.affiliate.manageCampaign.tableBody.totalDeposits')}</p></li>
              <li className="referrals"><p>{t('pages.affiliate.manageCampaign.tableBody.totalWagered')}</p></li>
              <li className="totalCommission"><p>{t('pages.affiliate.manageCampaign.tableBody.totalCommission')}</p></li>
              <li className="arrow"></li>
            </ul>
            <div className="accordion-table-container__body">
              {list?.length ? list.map((item, index) => {
                return <AccordionItem key={index} active={active} toggle={toggle} item={item} index={index} rewardRatio={rewardRatio} originRewardRatio={originRewardRatio} email={email} />
              }) : null}
            </div>
            {!list?.length ? <div className="no-list"><img src={NoListLogo} alt="no list" /></div> : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default function SubAffiliateTable(props) {
  const { email, commision } = props;
  const [sendTokenOpen, setSendTokenOpen] = useState(false);

  const sendTokenModalOpen = () => {
    setSendTokenOpen(true);
  };
  const sendTokenModalClose = () => {
    setSendTokenOpen(false);
  };

  if (!email || !commision) return null;

  return (
    <>
      <div className="campaign-content">
        <div className="campaign-content__title">
          <p className="info"><span>{email}</span> <br className="break" />Performance Review and Claim</p>
          <BaseButton onClick={sendTokenModalOpen} color="danger" label="component.modal.sendToken.title" size="small" isDisabled={!email} />
        </div>
        <TableBody {...props} />
      </div>
      <SendTokenModal open={sendTokenOpen} handleClose={sendTokenModalClose} toEmail={email} />
    </>
  )
}