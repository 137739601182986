export const SNS_ITEMS = [
  {
    name: 'Medium',
    link: 'https://medium.com/@ACECASINO ',
    icon: 'medium',
  },
  {
    name: 'Facebook',
    link: 'https://www.facebook.com/acecasino.io',
    icon: 'facebook',
  },
  {
    name: 'Twitter',
    link: 'https://twitter.com/ACECASINOio ',
    icon: 'twitter-x',
  },
  {
    name: 'YouTube',
    link: 'https://www.youtube.com/@ACECASINOio ',
    icon: 'youtube',
  },
  // {
  //   name: 'Discord',
  //   link: '/#',
  //   icon: 'discord',
  // },
]