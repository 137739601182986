import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modalType: "",
  isOpen: false,
  data: null,
  // 모달 위에 모달 컴포넌트
  twoFactorModal: false,
  searchView: false
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, actions) => {
      const { modalType, data } = actions.payload;
      state.modalType = modalType;
      state.isOpen = true;
      state.data = data;
    },
    closeModal: (state) => {
      state.modalType = "";
      state.isOpen = false;
      state.data = null;
    },
    setTwoFactorModal: (state, actions) => {
      state.twoFactorModal = actions.payload;
    },
    setSearchView: (state, actions) => {
      state.searchView = actions.payload;
    },
  },
});

export default modalSlice.reducer;
export const selectModal = (state) => state.components;
export const { openModal, closeModal, setTwoFactorModal, setSearchView } = modalSlice.actions;