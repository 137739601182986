export function LocalRegister() {
  // 기존 서비스워커 제거
  // navigator.serviceWorker.getRegistrations().then(function(registrations) {
  //   for (let registration of registrations) {
  //     registration.unregister();
  //   }
  // });
  const swPath = `${process.env.PUBLIC_URL}/sw.js`;
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(swPath)
    // .then(registration => {
    //   registration.onupdatefound = () => {
    //     const installation = registration.installing;
    //     installation.onstatechange = () => {
    //       if (installation.state === 'activated') {
    //         console.log('%cSW Activated', 'font-size: 1.2rem; color: green; font-weight: bolder');
    //       }
    //     }
    //   }
    // })
    // .catch(error => {
    //   console.log('%cError while registering SW:', 'font-size: 1.2rem; color: red; font-weight: bolder');
    //   console.log(error);
    // });
  }
}