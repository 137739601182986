import { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { getUserAffiliateListAPI, userSendTokenAPI } from 'api/affilate';
import { getUserBetTotalAPI } from 'api/user';

import NoListLogo from 'assets/Logo/logo_no_list.png';

import { toNumberFormat } from 'utils/toNumberFormat';
import { getTier } from 'utils/userTier';
import useUserInfoUpdate from 'hooks/useUserInfoUpdate'

import { CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Select from 'components/ui/select/Select'
import CurrencySelect from 'components/ui/select/CurrencySelect';
import BaseButton from 'components/ui/button/BaseButton';
import FormInput from 'components/ui/input/FormInput';
import { useSnackbar } from "notistack";

function transformString(inputString) {
  return inputString.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}

function SendTokenModal({ open, handleClose, subUserList }) {
  const { t } = useTranslation();
  const { updateUserBalance, sortUserBalance } = useUserInfoUpdate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const currentSymbol = useSelector(state => { return state?.user?.currentSymbol; });
  const userInfo = useSelector(state => { return state?.user?.userInfo; });
  const { email: fromEmail, balance } = userInfo;

  const [currency, setCurrency] = useState(null);
  const [allBalanceList, setAllBalanceList] = useState([]);
  const [toEmail, setToEmail] = useState(null);
  const [amount, setAmount] = useState(null);
  const [loading, setLoading] = useState(false);

  const data = { select: currency, setSelect: setCurrency, selectList: allBalanceList, id: "coin-select-deposit", color: "layer-02" }
  const selectSubUserData = { selectList: subUserList, id: "sub-user-send-select", select: toEmail, setSelect: setToEmail, color: "layer-02" };
  const amountInput = {
    id: 'sendToken-amount',
    label: 'component.modal.sendToken.amount.label',
    msg: `${t('commonDesc.balance')}: ${currency?.balance ? toNumberFormat(currency?.balance, 8) : "0.00000000"} ${currency?.symbol}`,
    isBalance: true,
    isDisabled: !currency?.balance,
    value: amount,
    setValue: setAmount,
    isMax: () => {
      if (currency?.balance) {
        setAmount(currency?.balance)
      }
    }
  }

  useEffect(() => {
    updateUserBalance()
  }, [])

  useEffect(() => {
    setAmount(null)
  }, [currency?.symbol, toEmail])

  useEffect(() => {
    if (subUserList?.length) {
      setToEmail(subUserList[0])
    }
  }, [subUserList])

  useEffect(() => {
    const list = sortUserBalance('total');
    setAllBalanceList(list);

    if (currentSymbol && balance.length) {
      const currentSelect = list.find(item => item.symbol === currentSymbol.symbol);
      setCurrency(currentSelect)
    }
  }, [balance])

  const userSendToken = async () => {
    setLoading(true)
    try {
      const res = await userSendTokenAPI(fromEmail, currency?.symbol, amount, toEmail)
      if (res.status === "success") {
        enqueueSnackbar({ msg: "component.modal.sendToken.sendSuccess", variant: "success", action: () => {closeSnackbar()} })
        handleClose()
        updateUserBalance()
      }
    } catch(e) {
      console.error('userSendTokenAPI e: ', e)
      enqueueSnackbar({ msg: e.message, variant: "error", action: () => {closeSnackbar()} })
    } finally {
      setLoading(false)
    }
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="base-dialog-wrapper content-dialog-wrapper small"
    >
      <div className="base-dialog-inner bottom-p-32">
        <div className="base-dialog-header">
          <p className="base-dialog-header__title medium">{t('component.modal.sendToken.title')}</p>
          <button onClick={handleClose} className="close-btn"></button>
        </div>
        <div className="base-dialog-body sendToken">
          <div className="send-user-form">
            <div className="select-wrapper">
              <div className="select-inner">
                <p className="box-title">{t('component.modal.sendToken.to')}</p>
                {toEmail ? <Select {...selectSubUserData} notation="none" /> : null}
              </div>
              <div className="select-inner">
                <p className="box-title">{t('component.modal.selectCoin')}</p>
                {currency ? <CurrencySelect {...data} /> : null}
              </div>
            </div>
            <FormInput inputText="number" labelSize="large" {...amountInput} />
          </div>
          <BaseButton isLoading={loading} isDisabled={!fromEmail || !toEmail || amount > currency?.balance-0 || 0 >= currency?.balance-0 || !amount || amount <= 0 || loading || !currency} onClick={() => userSendToken()} size="large" color="primary" label="component.modal.sendToken.title" />
        </div>
      </div>
    </Dialog>
  );
}

const AccordionItem = (props) => {
  const { t } = useTranslation();
  // const userInfo = useSelector(state => { return state?.user?.userInfo; });
  const contentEl = useRef();
  const { toggle, active, item, index } = props;
  const { email, username, commission, wagered, deposit_sum, create_at, withdraw_sum, user_balance } = item;
  const [childTier, setChildTier] = useState(null);
  const tableHead = [
    "pages.affiliate.referredUsers.tableBody.cryptos",
    "pages.affiliate.referredUsers.tableBody.balance",
    "pages.affiliate.referredUsers.tableBody.bonus",
    "pages.affiliate.referredUsers.tableBody.deposit",
    "pages.affiliate.referredUsers.tableBody.withdraw",
    "pages.affiliate.referredUsers.tableBody.commission"
  ]

  const getChildUserInfo = async () => {
    const res = await getUserBetTotalAPI(email);
    let total = 0;
    if (res?.length) {
      res.forEach(item => { total += item.value-0; })
    }
    const tier = getTier(total)
    setChildTier(tier);
  }

  useEffect(() => {
    if (email) {
      getChildUserInfo()
    }
  }, [email])

  return (
    <div className="accordion-item">
      <ul className={`accordion-item__head`} onClick={() => toggle(user_balance, index)}>
        <li className="userName">
          {childTier ? <img className="grade-image" src={require(`assets/Grade/${childTier}.png`)} alt={`${childTier} grade icon`} /> : <div className="grade-back"></div>}
          <p>{username ? username : "-"}</p>
        </li>
        <li className="userId"><p>{email ? email : "-"}</p></li>
        <li className="totalDeposits"><p>{deposit_sum ? toNumberFormat(deposit_sum, 6): "-"}</p></li>
        <li className="totalDeposits"><p>{withdraw_sum ? toNumberFormat(withdraw_sum, 6): "-"}</p></li>
        <li className="totalWagered"><p>{wagered ? toNumberFormat(wagered, 6): "-"}</p></li>
        <li className="commissionEarned"><p>{commission ? toNumberFormat(commission, 6) : "-"}</p></li>
        {/* <li className="date"><p>{create_at ? new Date(create_at).toISOString().replace(/T|Z/g," ").slice(0,16): "-"}</p></li> */}
        <li className={`arrow ${active === index ? 'active' : ''}`}></li>
      </ul>
      {(active !== null && active === index) ?
      <div ref={contentEl} className={`accordion-item__body ${active === index ? 'show' : ''}`}
        style={active === index && user_balance?.length ? { height: "auto" } : { height: "0px" }}>
          <div className="user-inner">
            {/* <ul className="user-inner__data">
              <li>
                <span>{t('pages.affiliate.referredUsers.tableHead.totalDeposits')}</span>
                <p>-</p>
              </li>
              <li>
                <span>{t('pages.affiliate.referredUsers.tableHead.totalWagered')}</span>
                <p>-</p>
              </li>
              <li>
                <span>{t('pages.affiliate.referredUsers.tableHead.commissionEarned')}</span>
                <p>-</p>
              </li>
            </ul> */}
            <div className="user-inner__inner">
              <div className="user-inner__inner--info">
                <div className="top">
                  <div className="user-name">
                    <p>{username ? username : "-"}</p>
                    {childTier ? <img className="grade-image" src={require(`assets/Grade/${childTier}.png`)} alt={`${childTier} grade icon`} /> : null}
                  </div>
                  <div className="user-email">
                    <p>{email ? email : "-"}</p>
                  </div>
                </div>
                <p className="date">{t('pages.affiliate.referredUsers.tableHead.registrationDate')}: {create_at ? new Date(create_at).toISOString().replace(/T|Z/g," ").slice(0,16): "-"}</p>
              </div>
              <div className="table-section-wrapper">
                <div className="table-section-inner">
                  <div className="table-container user-list">
                    <table className="table-inner">
                      <colgroup>
                        {tableHead.map((head, index) => {
                          return <col key={index} className={transformString(head)} />;
                        })}
                      </colgroup>
                      <thead className="table-inner__head">
                        <tr>
                        {tableHead.map((head, index) => {
                          return <th key={index} className={head}>{t(head)}</th>
                        })}
                        </tr>
                      </thead>
                      <tbody className="table-inner__body">
                        {user_balance?.length ? user_balance.map((item, index) => {
                          return <tr key={index}>
                            <td><p>{item.symbol ? item.symbol : '-'}</p></td>
                            <td><p>{item.balance ? item.balance : '-'}</p></td>
                            <td><p>{item.bonus ? item.bonus : '-'}</p></td>
                            <td><p>{item.deposit ? item.deposit : '-'}</p></td>
                            <td><p>{item.withdraw ? item.withdraw : '-'}</p></td>
                            <td><p>{item.commission ? item.commission : '-'}</p></td>
                          </tr>
                        }) : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="actions-btn">
              <BaseButton size="small" color="gray" label="pages.affiliate.referredUsers.sendEmail" />
              <BaseButton size="small" color="gray" label="pages.affiliate.referredUsers.sendMsg" />
              <BaseButton size="small" color="white" label="pages.affiliate.referredUsers.sendReward" />
            </div> */}
          </div>
      </div> : null }
    </div>
  )
}

function TableBody(props) {
  const { list, active, setActive, loading } = props;
  const { t } = useTranslation();
  const toggle = (item, index) => {
    if (active === index) {
      setActive(null);
    } else {
      const filteredData = item?.some(item =>
        item.balance !== 0 ||
        item.bonus !== 0 ||
        item.deposit !== 0 ||
        item.withdraw !== 0 ||
        item.commission !== 0
      );
      if (!filteredData || !item?.length) return;
      setActive(index);
    }
  }
  return (
    <div className="referred-content__body">
      <div className="accordion-table-container">
        <div className="accordion-table-container__inner">
          <ul className="accordion-table-container__head">
            <li className="userName"><p>{t('pages.affiliate.referredUsers.tableHead.username')}</p></li>
            <li className="userId"><p>{t('pages.affiliate.referredUsers.tableHead.userID')}</p></li>
            <li className="totalDeposits"><p>{t('pages.affiliate.referredUsers.tableHead.totalDeposits')}</p></li>
            <li className="totalDeposits"><p>{t('pages.affiliate.referredUsers.tableHead.totalWithdraw')}</p></li>
            <li className="totalWagered"><p>{t('pages.affiliate.referredUsers.tableHead.totalWagered')}</p></li>
            <li className="commissionEarned"><p>{t('pages.affiliate.referredUsers.tableHead.commissionEarned')}</p></li>
            {/* <li className="date"><p>{t('pages.affiliate.referredUsers.tableHead.registrationDate')}</p></li> */}
            <li className="arrow"></li>
          </ul>
          {loading ? <div className="accordion-table-container__loading">
            <CircularProgress color="inherit" size={60} />
          </div>  :
          <div className="accordion-table-container__body">
            {list?.length ? list.map((item, index) => {
              return <AccordionItem key={index} active={active} toggle={toggle} item={item} index={index} />
            }) : null}
          </div>}
          {!loading && !list?.length ? <div className="no-list"><img src={NoListLogo} alt="no list" /></div> : null}
        </div>
      </div>
    </div>
  )
}

function TableHeader({state, setState, campaignNameList, campaignSubUserList}) {
  const { t } = useTranslation();
  const selectData = { selectList: campaignNameList, id: "campaignName", select: state, setSelect: setState };
  const [sendTokenOpen, setSendTokenOpen] = useState(false);
  const sendTokenModalOpen = () => {
    setSendTokenOpen(true);
  };
  const sendTokenModalClose = () => {
    setSendTokenOpen(false);
  };

  return (
    <>
      <div className="referred-content__head">
        <div className="referred-content__head--select">
          <p>{t('pages.affiliate.referredUsers.selectLabel')}</p>
          <Select {...selectData} />
        </div>
        <div className="referred-content__head--sendToken">
          <BaseButton isDisabled={!campaignSubUserList?.length} onClick={sendTokenModalOpen} size="small" color="gray" label="pages.affiliate.referredUsers.sendToken" />
        </div>
      </div>
      <SendTokenModal open={sendTokenOpen} handleClose={sendTokenModalClose} subUserList={campaignSubUserList} />
    </>
  )
}

export default function ReferredUsers(props) {
  const userEmail = useSelector(state => { return state?.user?.userInfo.email; });
  const { campaignList } = props;
  const [state, setState] = useState(null);
  const [campaignNameList, setCampaignNameList] = useState(null);
  const [campaignSubList, setCampaignSubList] = useState([]);
  const [campaignSubUserList, setCampaignSubUserList] = useState([]);
  const [active, setActive] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (campaignList?.length) {
      const list = campaignList?.map(item => item.campaign_name);
      setState(list[0])
      setCampaignNameList(list)
    }
  }, [campaignList])

  const getUserAffiliateList = async () => {
    if (!state) return;
    setLoading(true)
    try {
      const res = await getUserAffiliateListAPI(userEmail, state);
      const filterCampaignSubUserList = res.filter(item => item.email !== null)?.map(item => item.email );
      setCampaignSubList(res);
      setCampaignSubUserList(filterCampaignSubUserList)
    } catch(e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getUserAffiliateList()
    if (active !== null) {
      setActive(null);
    }
  }, [state]);

  const bodyData = { ...props, state, list: campaignSubList, active, setActive, loading }

  return (
    <div className="referred-content">
      {state && <TableHeader state={state} setState={setState} campaignNameList={campaignNameList} campaignSubUserList={campaignSubUserList} />}
      <TableBody {...bodyData} />
    </div>
  )
}