// import React, { useState } from 'react';
import { OutlinedInput } from '@mui/material';
import { useTranslation } from 'react-i18next'

export default function FormInput(props) {
  const { t } = useTranslation();
  const { id, placeholder, value, setValue, close, autoFocus, isChange, isFunction } = props;

  const onChange = (e)=> {
    if (isChange) {
      isChange(e)
      return;
    }
    const inputValue = e.target.value;
    setValue(inputValue)
  }

  return (
    <div className="search-input-wrapper">
      <OutlinedInput
        className={id}
        autoComplete="off"
        autoFocus={autoFocus}
        placeholder={t(placeholder)}
        name={id}
        id={id}
        type={"text"}
        value={value || ''}
        onChange={onChange}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter' && isFunction) isFunction()
        }}
        startAdornment={<div className="search-icon"></div>}
        endAdornment={close ?<button onClick={close} className="close-btn"></button> : null}
         />
    </div>
  )
}