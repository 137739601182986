import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BaseButton from 'components/ui/button/BaseButton';
import { getMarketingSubscribedAPI, addSubscribedAPI } from 'api/user'
import { useTranslation } from 'react-i18next'
import useAuth from 'hooks/useAuth';


export default function Preference() {
  const { t } = useTranslation();
  const userInfo = useSelector(state => { return state?.user?.userInfo; });
  const { logout } = useAuth();
  const [subscribed, setSubscribed] = useState(null);
  const [loading, setLoading] = useState(false);

  const getMarketingSubscribed = async () => {
    const email = userInfo?.email
    if (!email) return;
    setLoading(true)
    try {
      const res = await getMarketingSubscribedAPI(email)
      setSubscribed(res?.marketing);
    } catch(e) {
      console.log("e: ", e)
    } finally {
      setLoading(false);
    }
  }

  const addSubscribed = async () => {
    const email = userInfo?.email
    if (!email) return;
    setLoading(true)
    try {
      const res = await addSubscribedAPI(email, !subscribed)
      setSubscribed(res?.marketing);
      getMarketingSubscribed();
    } catch(e) {
      console.log("addSubscribedAPI e: ", e)
    }
  }

  useEffect(() => {
    getMarketingSubscribed()
  }, [])

  return (
    <div className="preference-content settings-content">
      <div className="box-layout">
        <div className="settings-content__title">
          <p>{t('pages.settings.preference.marketing.title')}</p>
          <span>{t('pages.settings.preference.marketing.info')}</span>
        </div>
        <div className="box-layout__inner">
          <BaseButton isLoading={loading} onClick={() => addSubscribed(false)} color="primary" label={`${subscribed ? "pages.settings.preference.marketing.UnsubscribeBtn" : "pages.settings.preference.marketing.subscribeBtn"}`} />
        </div>
      </div>
      <div className="box-layout">
        <div className="settings-content__title">
          <p>{t('pages.settings.preference.sessions.title')}</p>
          <span>{t('pages.settings.preference.sessions.info')}</span>
        </div>
        <div className="box-layout__inner">
          <BaseButton onClick={() => logout(true)} color="primary" label="pages.settings.preference.sessions.removeBtn" />
        </div>
      </div>
    </div>
  )
}