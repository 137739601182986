import axios from 'axios';
import { fetchAuthSession, signUp, signIn, signInWithRedirect, confirmSignUp, resendSignUpCode, signOut, currentSession } from 'aws-amplify/auth';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { movePath } from 'utils/movePath';
import { clearUserInfo, setSessionLoading, setUserToken, setUserInfo, setUserHasOtp, setUserBet } from 'store/modules/user';
import { useSnackbar } from "notistack";
import { getUserInfoAPI, addUserCreateAPI, hasOtpAPI, getUserBetTotalAPI, disableAllAccessTokenAPI, hasManagedUserAPI, loginManagedUserAPI, addManagedUserAPI } from 'api/user';
import { addAffiliateAPI } from 'api/affilate';
import { postRedeemCodeAPI } from 'api/pragmatic';
import { getCookie, deleteCookie } from 'utils/cookie';
import { getTier, getNextTier } from 'utils/userTier';
import useUserInfoUpdate from './useUserInfoUpdate';

let BASE_URL;
if (window.location.hostname === "www.acecasino.io") {
  BASE_URL = "https://api.acecasino.io"
} else {
  BASE_URL = "https://ntestapi.acecasino.io"
}

const useAuth = () => {
  const { updateUserBalance } = useUserInfoUpdate()
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const storeUser = useSelector(state => { return state?.user; });
  const { userInfo, idToken, language, sessionLoading, managedRegisterData, isLoggedIn } = storeUser;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getAwsSessionToken = async () => {
    try {
      const { accessToken, idToken } = (await fetchAuthSession({ forceRefresh: true }))?.tokens ?? {};
      return { accessToken, idToken };
    } catch(e) {
      return null
      // console.error('e: ', e);
    }
  }

  const setSessionToken = async () => {
    if (pathname?.includes('managedLogin')) return; // managedLogin의 경우 home.js에서 토큰을 셋팅하기에 제외한다
    if (!sessionLoading || isLoggedIn) {
      dispatch(setSessionLoading(true))
    }
    const hasManagedToken = localStorage.getItem('managedUserAuthToken');
    try {
      // 사용자가 로그인되어 있는 경우 해당 사용자의 세션 정보를 반환
      if (hasManagedToken) { // 자체관리 이메일 유저
        dispatch(setUserToken(JSON.parse(hasManagedToken)))
      } else { // aws 이메일 유저
        const { accessToken, idToken } = await getAwsSessionToken()
        dispatch(setUserToken({jwtToken: accessToken?.toString(), idToken: idToken?.payload}))
      }
    } catch (e) {
    } finally {
      dispatch(setSessionLoading(false))
    }
  }

  const getUserBetTotal = async (userEmail) => {
    // 사용자 티어 조회
    if (!userEmail) return;
    try {
      const res = await getUserBetTotalAPI(userEmail);
      let total = 0;
      if (res?.length) {
        res.forEach(item => { total += item?.value-0; })
      }
      const currentTier = getTier(total)
      const nextTier = getNextTier(total)
      dispatch(setUserBet({ wagerAmount: total, currentTier, nextTier }));
    } catch (e) {
      console.error('getUserBetTotal e:', e);
    }
  }

  const setInitUserData = async (userEmail) => {
    // 세션 확인 시 로그인 로직
    if (!userEmail) return;
    dispatch(setSessionLoading(true))
    const referralCode = getCookie('referralCode');

    try {
      const res = await getUserInfoAPI(userEmail);
      if (res.err === "User not found") {
        // 최초 로그인 사용자 정보 셋팅
        await addUserCreateAPI(userEmail, idToken.name);
        if (referralCode) {
          await addAffiliateAPI(userEmail, referralCode);
          deleteCookie('referralCode')
        }
        await setInitUserData(userEmail);
        return;
      }

      // 기존 로그인 사용자 정보 조회
      dispatch(setUserInfo(res));
      await getUserBetTotal(userEmail)
      const hasOtp = await hasOtpAPI(userEmail);
      if (hasOtp) dispatch(setUserHasOtp(hasOtp));
    } catch (e) {
      console.error('setInitUserData e:', e);
    } finally {
      if (referralCode) {
        movePath(nav, language, '/')
      }
      dispatch(setSessionLoading(false))
    }
  }

  const snsLogin = (snsName) => {
    // snsName: "Google", "Facebook"...
    try {
      signInWithRedirect({ provider: snsName });
    } catch(e) {
      console.error("signInWithRedirect e: ", e);
    }
  }

  const resendCode = async (data, setIsDisabled) => {
    // 컨펌 코드 이메일 재발송
    try {
      await resendSignUpCode(data)
      enqueueSnackbar({ msg: "component.modal.verify.resendSuccess", variant: "success", action: () => {closeSnackbar()} });
      setIsDisabled(true); // 다시 보내기 후 버튼 비활성화
    }
    catch(e) {
      enqueueSnackbar({ msg: e.message, variant: "error", action: () => {closeSnackbar()} });
      console.error('resendSignUpCode e: ', e);
    }
  }

  const confirmLogin = async (data, setLoading, handleClose, loginData, setConfirm) => {
    setLoading(true);
    try {
      const { isSignUpComplete, nextStep } = await confirmSignUp(data);
      if (isSignUpComplete && nextStep.signUpStep === "DONE") {
        if (loginData && setConfirm) {
          // 최초 로그인 시 이메일 컨펌 후 로그인
          await login(loginData, setLoading, setConfirm, handleClose);
        } else {
          // 회원가입 직후 이메일 컨펌 시에는 로그인 로직을 안타고 종료
          handleClose();
        }
        enqueueSnackbar({ msg: "component.modal.verify.verifySuccess", variant: "success", action: () => {closeSnackbar()} });
      }
    } catch (e) {
      enqueueSnackbar({ msg: e.message, variant: "error", action: () => {closeSnackbar()} });
      console.error('confirmSignUp e: ', e);
    } finally {
      if (!loginData && !setConfirm) {
        setLoading(false);
      }
    }
  }

  const setManagedToken = async (token, username) => {
    let tokenData = {jwtToken: null, idToken: null}
    const hasManagedToken = localStorage.getItem('managedUserAuthToken');

    if (!token || !username) return;
    dispatch(setSessionLoading(true))

    try {
      // 기존 managed 로그인 조회
      if (hasManagedToken) {
        const isManagedToken = JSON.parse(hasManagedToken)
        if (isManagedToken?.jwtToken === token && isManagedToken?.idToken.email === username) {
          return;
        }
        localStorage.removeItem('managedUserAuthToken');
        await axios.post(`${BASE_URL}/user/disableAllAccessToken`,
        { email: isManagedToken?.idToken.email },
        { headers: { 'Authorization': isManagedToken?.jwtToken, 'Email': isManagedToken?.idToken.email } }
        );
      }

      // token 검증
      await axios.get(`${BASE_URL}/user/marketing?email=${username}`, {
        headers: { 'Authorization': token, 'Email': username },
      });

      // aws-auth 연결된 session 확인
      const prevAwsToken = await getAwsSessionToken()
      if (prevAwsToken) {
        // session 종료
        dispatch(setUserToken(tokenData))
        dispatch(clearUserInfo())
        await signOut();
      }

      // set managed login
      tokenData = {jwtToken: token, idToken: { email: username }}
      dispatch(setUserToken(tokenData))
      localStorage.setItem('managedUserAuthToken', JSON.stringify(tokenData));
    } catch(e) {
      console.log("e: ", e)
    } finally {
      movePath(nav, language, '/')
      dispatch(setSessionLoading(false))
    }
  }

  const login = async (data, setLoading, setConfirm, handleClose) => {
    setLoading(true);
    const { username, password } = data;
    if (!username) return;

    try {
      const has = await hasManagedUserAPI(username)

      if (has) {
        const res = await loginManagedUserAPI(username, password)
        setManagedToken(res?.token, username)
        handleClose();
      } else {
        const { isSignedIn, nextStep } = await signIn(data)
        if (isSignedIn && nextStep.signInStep === "DONE") {
          // 로그인 완료
          await setSessionToken();
          handleClose();
        } else if (!isSignedIn && nextStep.signInStep === "CONFIRM_SIGN_UP") {
          // 최초 로그인 시 이메일 컨펌 화면으로 전환
          setConfirm(true)
        }
      }
    } catch(e) {
      console.error('login e: ', e)
      enqueueSnackbar({ msg: e.message, variant: "error", action: () => {closeSnackbar()} })
    } finally {
      setLoading(false);
    }
  };

  const logout = async (isGlobal) => {
    // 로그아웃 or 모든 기기의 세션 종료
    const global = !!isGlobal;
    const userEmail = userInfo?.email;
    const hasManagedToken = localStorage.getItem('managedUserAuthToken');
    try {
      if (global && userEmail) {
        await disableAllAccessTokenAPI(userEmail)
      }
      if (hasManagedToken) {
        localStorage.removeItem('managedUserAuthToken');
      } else {
        await signOut({ global });
      }
      dispatch(clearUserInfo())
      dispatch(setSessionLoading(true))
      if (!idToken?.identities) {
        // 이메일 로그인시에는 리로드를 해준다
        movePath(nav, language, '/')
        setTimeout(()=>{
          window.location.reload();
        }, 500);
      }
    } catch (e) {
      console.error('signOut e: ', e);
    }
  };

  const register = async (data, setLoading) => {
    setLoading(true);
    try {
      if (managedRegisterData?.email) {
        const { username, password, options } = data;
        const { userAttributes } = options;
        const referralCode = getCookie('referralCode');
        await addManagedUserAPI(userAttributes.name, username, password, referralCode, managedRegisterData?.addrbook);
        if (referralCode) {
          deleteCookie('referralCode')
        }
        movePath(nav, language, '/')
      } else {
        await signUp(data);
      }
      // if (data?.redeemCode !== null) {
      //   registerRedeemCode(data?.redeemCode);
      // }
      enqueueSnackbar({ msg: "commonMsg.signUp", variant: "success", action: () => {closeSnackbar()} })
    } catch (e) {
      enqueueSnackbar({ msg: e.message, variant: "error", action: () => {closeSnackbar()} })
      console.error('signUp e:', e);
    } finally {
      setLoading(false);
    }
  };

  const registerRedeemCode = async (code, setLoading, handleClose) => {
    if (!code) return;
    const userEmail = userInfo?.email;
    setLoading(true);

    try {
      const res = await postRedeemCodeAPI(userEmail, code);
      if (res?.sub_code && res?.email) {
        enqueueSnackbar({ msg: "component.modal.redeemCode.success", variant: "success", action: () => {closeSnackbar()} })
        handleClose()
        return;
      }
      if (res?.error !== "0") {
        enqueueSnackbar({ msg: res.description, variant: "error", action: () => {closeSnackbar()} })
      } else {
        enqueueSnackbar({ msg: "component.modal.redeemCode.success", variant: "success", action: () => {closeSnackbar()} })
        handleClose()
        if (pathname.includes("/slot/games/vs20sugarrush")) {
          updateUserBalance()
        } else {
          movePath(nav, language, '/slot/games/vs20sugarrush')
        }
      }
    } catch(e) {
      console.error("e", e)
      enqueueSnackbar({ msg: e.message, variant: "error", action: () => {closeSnackbar()} })
    } finally {
      setLoading(false);
    }
  };

  return { setSessionToken, setInitUserData, register, login, snsLogin, confirmLogin, resendCode, logout, setManagedToken, registerRedeemCode };
};

export default useAuth;