/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://4ihcydj5vvcrngs4ulihju4z5m.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-eba4kdonibhtneeywvi5gutmci",
    "aws_cognito_identity_pool_id": "us-east-1:affd5d6a-e8a2-4e49-a7e3-09f7e75791ba",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_aWzqKnudh",
    "aws_user_pools_web_client_id": "lir1p4n6ukc08v4obgl42vo9m",
    "oauth": {
        "domain": "i2euv7yctekl-staging.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://acecasino.io/,http://localhost:3000/,https://test.acecasino.io/,https://www.acecasino.io/,https://test.d11foezu5gud8o.amplifyapp.com/,https://main.d11foezu5gud8o.amplifyapp.com/",
        "redirectSignOut": "https://acecasino.io/,http://localhost:3000/,https://test.acecasino.io/,https://www.acecasino.io/,https://test.d11foezu5gud8o.amplifyapp.com/,https://main.d11foezu5gud8o.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
