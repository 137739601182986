// import { useState, useEffect } from 'react';
// import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux';
import { selectModal, closeModal } from 'store/modules/components';
import Dialog from '@mui/material/Dialog';
import BaseButton from 'components/ui/button/BaseButton';

export default function CommonNoticeModal() {
  const dispatch = useDispatch();
  const { modalType, isOpen } = useSelector(selectModal);
  // const { t } = useTranslation();

  const handleClose = () => { dispatch(closeModal()); };

  return (
    <Dialog
      open={modalType === "Notice" && isOpen}
      onClose={handleClose}
      className="base-dialog-wrapper content-dialog-wrapper small"
    >
      <div className="base-dialog-inner bottom-p-32">
        <div className="base-dialog-header">
          <p className="base-dialog-header__title medium">Notice</p>
          <button onClick={handleClose} className="close-btn"></button>
        </div>
        <div className="base-dialog-body notice">
          <div className="notice-wrapper">
            <div className="notice-wrapper__inner">
              <p>Due to the sync delay on TRON network, any deposit with TRON-based coins may be delayed. Please refer to this issue when using the service.</p>
            </div>
            <BaseButton onClick={handleClose} color="primary" label="Confirm" size="large" />
          </div>
        </div>
      </div>
    </Dialog>
  );
}