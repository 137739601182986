/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateLastChat = /* GraphQL */ `
  subscription OnCreateLastChat($filter: ModelSubscriptionLastChatFilterInput) {
    onCreateLastChat(filter: $filter) {
      id
      user
      create_at
      Chat {
        id
        user
        msg
        chatchannelsID
        create_at
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      lastChatChatId
      __typename
    }
  }
`;
export const onUpdateLastChat = /* GraphQL */ `
  subscription OnUpdateLastChat($filter: ModelSubscriptionLastChatFilterInput) {
    onUpdateLastChat(filter: $filter) {
      id
      user
      create_at
      Chat {
        id
        user
        msg
        chatchannelsID
        create_at
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      lastChatChatId
      __typename
    }
  }
`;
export const onDeleteLastChat = /* GraphQL */ `
  subscription OnDeleteLastChat($filter: ModelSubscriptionLastChatFilterInput) {
    onDeleteLastChat(filter: $filter) {
      id
      user
      create_at
      Chat {
        id
        user
        msg
        chatchannelsID
        create_at
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      lastChatChatId
      __typename
    }
  }
`;
export const onCreateChat = /* GraphQL */ `
  subscription OnCreateChat($filter: ModelSubscriptionChatFilterInput) {
    onCreateChat(filter: $filter) {
      id
      user
      msg
      chatchannelsID
      create_at
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateChat = /* GraphQL */ `
  subscription OnUpdateChat($filter: ModelSubscriptionChatFilterInput) {
    onUpdateChat(filter: $filter) {
      id
      user
      msg
      chatchannelsID
      create_at
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteChat = /* GraphQL */ `
  subscription OnDeleteChat($filter: ModelSubscriptionChatFilterInput) {
    onDeleteChat(filter: $filter) {
      id
      user
      msg
      chatchannelsID
      create_at
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateChatChannels = /* GraphQL */ `
  subscription OnCreateChatChannels(
    $filter: ModelSubscriptionChatChannelsFilterInput
  ) {
    onCreateChatChannels(filter: $filter) {
      id
      type
      lang
      Chats {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateChatChannels = /* GraphQL */ `
  subscription OnUpdateChatChannels(
    $filter: ModelSubscriptionChatChannelsFilterInput
  ) {
    onUpdateChatChannels(filter: $filter) {
      id
      type
      lang
      Chats {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteChatChannels = /* GraphQL */ `
  subscription OnDeleteChatChannels(
    $filter: ModelSubscriptionChatChannelsFilterInput
  ) {
    onDeleteChatChannels(filter: $filter) {
      id
      type
      lang
      Chats {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
