import { apiRequest } from 'utils/axios';

/**
 * 입금 주소 조회 API
 */
export const getDepositAddressAPI = async (email, currency) => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/wallet?email=${email}&currency=${currency}`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 입금 히스토리 리스트 조회 API
 */
export const getDepositHistoryAPI = async (email, page, limit, startDate, endDate) => {
  try {
    let url = `/wallet/depositHistory?email=${email}`;
    if (startDate) {
      url += `&startDate=${startDate}`;
    }
    if (endDate) {
      url += `&endDate=${endDate}`;
    }
    if (limit) {
      url += `&limit=${limit}`;
    }
    if (page) {
      url += `&page=${page}`;
    }
    const result = await apiRequest({
      method: 'get',
      url,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 입금 히스토리 리스트 조회 API
 */
export const addRequestWithdrawAPI = async (email, currency, toAddress, amount) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/wallet/requestWithdraw`,
      data: { email, currency, toAddress, amount }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}
