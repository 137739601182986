import ErrorImg from 'assets/Symbol/NONE.svg'

export default function SymbolImg({ symbol, className }) {
  let imagePath;

  try {
    imagePath = require(`../../assets/Symbol/${symbol}.svg`);
  } catch (error) {
    console.error(`Image for symbol ${symbol} not found, falling back to default image.`);
    imagePath = ErrorImg; // 이미지가 없는 경우 기본 이미지로 대체
  }

  return <img className={className} src={imagePath} alt={`${symbol ? symbol : 'none'} symbol img`} />;
}