import React, { useState } from 'react';
import { IconButton, OutlinedInput, FormHelperText } from '@mui/material';
import Visibility from 'assets/Icon/visibility.svg';
import VisibilityOff from 'assets/Icon/visibilityoff.svg';
import { useTranslation } from 'react-i18next'
import InputAdornment from '@mui/material/InputAdornment';

export default function FormInput(props) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const { label,
    id,
    labelSize,
    placeholder,
    msg,
    value,
    setValue,
    inputText,
    isDesc,
    isBalance,
    isPassword,
    isFunction,
    isDisabled,
    hasAddrBook,
    fetchAddress,
    handleBlur,
    handleFocus,
    isEndItem,
    isIntegerValue,
    isMax,
    isError } = props;

  const onChange = (e)=> {
    let inputValue = e.target.value;
    if (inputText === "number" && e.target.value <= 0) return;
    setValue(inputValue)
  }

  const preventDecimalInput = (event) => {
    // 소수점 입력 막기
    if (event.key === "." || event.key === "+" || event.key === "-") {
      event.preventDefault();
    }
  };

  return (
    <div className="form-input-wrapper">
      {label ? <p className={`form-input-lable ${labelSize ? labelSize : ''}`}>{t(label)}</p> : null}
      <OutlinedInput
        onBlur={()=> {
          if(handleBlur) handleBlur()
        }}
        onFocus={()=> {
          if(handleFocus) handleFocus()
        }}
        className={`${label} ${labelSize ? labelSize : ''} ${isPassword ? "Password" : ""} ${isDesc ? "isDesc" : ""} ${isEndItem ? "isEndItem" : ""}`}
        autoComplete={isPassword ? "new-password" : "off"}
        name={id}
        placeholder={t(placeholder)}
        id={id}
        type={inputText ? inputText : isPassword ? (showPassword ? 'text' : 'password') : "text"}
        value={value || ''}
        onChange={onChange}
        error={!!isError || false}
        disabled={isDisabled}
        onKeyDown={(ev) => {
          if (isIntegerValue) preventDecimalInput(ev)
          if (ev.key === 'Enter' && isFunction) isFunction()
        }}
        startAdornment={isDesc ? <InputAdornment position="start">{isDesc}</InputAdornment> : null}
        endAdornment={
          isPassword ?
          <div>
            <IconButton
              aria-label="toggle password"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <img src={VisibilityOff} alt="visibility off icon" /> : <img src={Visibility} alt="visibility icon" />}
            </IconButton>
          </div>
          : isBalance ?
          <button className="max-btn" onClick={() => isMax()}>{t('commonDesc.max')}</button>
          : hasAddrBook ?
            <button className="addrbook-btn" onClick={fetchAddress}>Tethys</button>
          : isEndItem ?
            <InputAdornment position="end">{isEndItem}</InputAdornment>
          : null
        } />
        {(!!isError && typeof isError === 'string') && (
          <FormHelperText className="form-input-error-msg" error id={`${label}-error`}>
            {t(isError)}
          </FormHelperText>
        )}
        {msg && <span className={`form-input-msg ${isBalance ? 'balance' : ''}`}>{t(msg)}</span>}
    </div>
  )
}