
import { getUserInfoAPI } from 'api/user';
import { useSelector, useDispatch } from 'react-redux';
import { setUserInfo } from 'store/modules/user'
import { sort } from 'utils/sort';

const useUserInfoUpdate = () => {
  const userInfo = useSelector(state => { return state?.user?.userInfo; });
  const dispatch = useDispatch();

  const updateUserBalance = async () => {
    if (!userInfo?.email) return;
    try {
      const res = await getUserInfoAPI(userInfo?.email);
      dispatch(setUserInfo(res));
    } catch (e) {
      console.error('Error:', e);
    }
  };

  const sortUserBalance = (type) => {
    let list = [];
    if (userInfo?.balance?.length) {
      for (const [key, items] of Object.entries(userInfo?.currency)) {
        const balanceItem = userInfo.balance?.find(itm => itm?.symbol === key);
        list.push(balanceItem)
      }
      return sort(list, type);
    }
    return [];
  }

  return { updateUserBalance, sortUserBalance };
};

export default useUserInfoUpdate