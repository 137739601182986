import { useState, useEffect } from 'react';
import { getGameListAPI } from 'api/pragmatic'
import SearchInput from 'components/ui/input/SearchInput'
import Select from 'components/ui/select/Select'
import GameCard from 'components/item/Card';
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@mui/material';

const sortList = [
  {
    title: "A-Z",
    value: "ASC"
  },
  {
    title: "Z-A",
    value: "DESC"
  },
]

export default function GameList(props) {
  const { t } = useTranslation();
  const { gameType, title, iconName } = props;
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([])
  const [state, setState] = useState(sortList[0]);
  const [keyword, setKeyword] = useState(null);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(null);
  const selectData = { selectList: sortList, id: `${gameType}-game-select`, select: state, setSelect: setState };
  const limit = 30;

  const searchInputStyle = {
    id: "game-search",
    placeholder: 'component.search.placeholder',
    value: keyword,
    setValue: setKeyword,
    isFunction: () => getGameList()
  }

  const getGameList = async () => {
    if (loading) return;
    setLoading(true);
    setPage(1);
    const p = 1
    try {
      const res = await getGameListAPI(gameType, keyword, p, limit, state.value)
      setList(res?.games)
      const listCount = Math.ceil((res?.games[0].totalCount-0) / limit);
      setCount(listCount);
    } catch (e) {
      console.log("e: ", e);
    } finally {
      setLoading(false);
    }
  }

  const pushGameList = async (nextPage) => {
    setLoading(true);
    try {
      const res = await getGameListAPI(gameType, keyword, nextPage, limit, state.value)
      if (Array.isArray(res?.games)) {
        setList(prevState => [...prevState, ...res?.games]);
      }
    } catch (e) {
      console.log("e: ", e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (page > 1) {
      pushGameList(page)
    }
  }, [page]);

  useEffect(() => {
    if (state) {
      getGameList()
    }
  }, [state]);

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && !loading) {
      setPage((prevPage) => {
        if (prevPage === count) return prevPage;
        return prevPage + 1;
      });
    }
  };

  useEffect(() => {
    if (!list?.length) return;
    const observer = new IntersectionObserver(handleObserver, {
      threshold: 0,
    });
    const observerTarget = document.getElementById("observer");
    if (observerTarget) {
      observer.observe(observerTarget);
    }
  }, [count]);

  const morePage = () => {
    if (loading) return;
    const nextPage = page + 1;
    setPage(nextPage);
    pushGameList(nextPage);
  }

  return (
    <div className="game-wrapper section-wrapper">
      <div className="section-wrapper__header">
        <p className={iconName}>{t(title)}</p>
        <div className="filter-wrapper">
          <SearchInput {...searchInputStyle} />
          <Select {...selectData} />
        </div>
      </div>
      <div className="game-inner">
        <div className="game-list">
          {list.length ? list?.map((item, index) => {
            return <GameCard gameData={{gameType, title, iconName}} key={index} item={item} />
          }) : null }
          <div id="observer" style={{ height: "10px" }}></div>
        </div>
        {loading && <div className="more-wrapper" onClick={morePage}>
          <CircularProgress color="inherit" />
        </div>}
      </div>
    </div>
  )
}