import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { setLanguage } from 'store/modules/user';
import { setScreen } from 'store/modules/ui';
import { Outlet } from 'react-router-dom';
import Header from './components/common/header/Header';
import Footer from './components/common/Footer';
import GnbSidebar from './components/common/sidebar/GnbSidebar';
import OptionSidebar from './components/common/sidebar/OptionSidebar';
import MobileNavigation from './components/common/header/MobileNavigation';
import useWindowSizeCustom from "utils/useWindowSizeCustom";
import ScrollToTop from 'components/common/ScrollToTop';
import i18n from "./locales/i18n";
import ThemeResponsiveSnackbar from "components/snackbar/NormalSnackBar"
import GlobalModal from 'components/modal/Global';
import SearchView from 'components/common/SearchView';
import { movePath } from 'utils/movePath';
import { setSearchView } from 'store/modules/components';
import { selectModal } from 'store/modules/components';
import { SUPPORT_LANG as supportLanguage } from 'constants/language'
import { openModal } from 'store/modules/components';

const Layout = () => {
  const nav = useNavigate();
  const { language } = useParams();
  const dispatch = useDispatch();
  const { searchView } = useSelector(selectModal);
  const screenSize = useWindowSizeCustom();
  const screen = useSelector(state => {
    return state?.ui?.screen;
  });
  const isMobile = screen.viewType.mobile;
  const userLanguage = useSelector(state => {
    return state?.user?.language;
  });
  const currentLanguage = supportLanguage.find(item => language === item);
  const [contentWrapperHeight, setContentWrapperHeight] = useState(null);

  const handleClose = () => {
    dispatch(setSearchView(false));
  };
  const useOutsideClick = (callback) => {
    const ref = useRef(null);
    useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, [callback, ref]);

    return ref;
  };
  const ref = useOutsideClick(handleClose);

  useEffect(() => {
    if (!currentLanguage) {
      movePath(nav, userLanguage, '')
      return;
    }
  }, []);

  useEffect(() => {
    dispatch(setScreen(screenSize));
  }, [screenSize, dispatch]);

  useEffect(() => {
    if (currentLanguage) {
      i18n.changeLanguage(language);
      dispatch(setLanguage(language));
    }
  }, [language, dispatch]);

  if (!userLanguage || !screen.size.width) {
    return null;
  }

  return (
    <SnackbarProvider maxSnack={1}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: isMobile ? 'center' : 'right',
      }}
      Components={{
        info: ThemeResponsiveSnackbar,
        default: ThemeResponsiveSnackbar,
        success: ThemeResponsiveSnackbar,
        error: ThemeResponsiveSnackbar,
        warning: ThemeResponsiveSnackbar
      }}>
      <div className="wrapper">
        <GnbSidebar />
        <div ref={ref} className="main-content">
          <Header />
          <main className="content-wrapper" id="scrollbar">
            <ScrollToTop>
              <div className="container">
                {currentLanguage && <Outlet />}
              </div>
              <Footer />
            </ScrollToTop>
            {searchView && <SearchView />}
          </main>
          {isMobile && <MobileNavigation />}
        </div>
        <OptionSidebar />
      </div>
      <GlobalModal />
    </SnackbarProvider>
  );
}

export default Layout;