/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createLastChat = /* GraphQL */ `
  mutation CreateLastChat(
    $input: CreateLastChatInput!
    $condition: ModelLastChatConditionInput
  ) {
    createLastChat(input: $input, condition: $condition) {
      id
      user
      create_at
      Chat {
        id
        user
        msg
        chatchannelsID
        create_at
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      lastChatChatId
      __typename
    }
  }
`;
export const updateLastChat = /* GraphQL */ `
  mutation UpdateLastChat(
    $input: UpdateLastChatInput!
    $condition: ModelLastChatConditionInput
  ) {
    updateLastChat(input: $input, condition: $condition) {
      id
      user
      create_at
      Chat {
        id
        user
        msg
        chatchannelsID
        create_at
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      lastChatChatId
      __typename
    }
  }
`;
export const deleteLastChat = /* GraphQL */ `
  mutation DeleteLastChat(
    $input: DeleteLastChatInput!
    $condition: ModelLastChatConditionInput
  ) {
    deleteLastChat(input: $input, condition: $condition) {
      id
      user
      create_at
      Chat {
        id
        user
        msg
        chatchannelsID
        create_at
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      lastChatChatId
      __typename
    }
  }
`;
export const createChat = /* GraphQL */ `
  mutation CreateChat(
    $input: CreateChatInput!
    $condition: ModelChatConditionInput
  ) {
    createChat(input: $input, condition: $condition) {
      id
      user
      msg
      chatchannelsID
      create_at
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateChat = /* GraphQL */ `
  mutation UpdateChat(
    $input: UpdateChatInput!
    $condition: ModelChatConditionInput
  ) {
    updateChat(input: $input, condition: $condition) {
      id
      user
      msg
      chatchannelsID
      create_at
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteChat = /* GraphQL */ `
  mutation DeleteChat(
    $input: DeleteChatInput!
    $condition: ModelChatConditionInput
  ) {
    deleteChat(input: $input, condition: $condition) {
      id
      user
      msg
      chatchannelsID
      create_at
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createChatChannels = /* GraphQL */ `
  mutation CreateChatChannels(
    $input: CreateChatChannelsInput!
    $condition: ModelChatChannelsConditionInput
  ) {
    createChatChannels(input: $input, condition: $condition) {
      id
      type
      lang
      Chats {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateChatChannels = /* GraphQL */ `
  mutation UpdateChatChannels(
    $input: UpdateChatChannelsInput!
    $condition: ModelChatChannelsConditionInput
  ) {
    updateChatChannels(input: $input, condition: $condition) {
      id
      type
      lang
      Chats {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteChatChannels = /* GraphQL */ `
  mutation DeleteChatChannels(
    $input: DeleteChatChannelsInput!
    $condition: ModelChatChannelsConditionInput
  ) {
    deleteChatChannels(input: $input, condition: $condition) {
      id
      type
      lang
      Chats {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
