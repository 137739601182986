/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getLastChat = /* GraphQL */ `
  query GetLastChat($id: ID!) {
    getLastChat(id: $id) {
      id
      user
      create_at
      Chat {
        id
        user
        msg
        chatchannelsID
        create_at
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      lastChatChatId
      __typename
    }
  }
`;
export const listLastChats = /* GraphQL */ `
  query ListLastChats(
    $filter: ModelLastChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLastChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        create_at
        createdAt
        updatedAt
        lastChatChatId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChat = /* GraphQL */ `
  query GetChat($id: ID!) {
    getChat(id: $id) {
      id
      user
      msg
      chatchannelsID
      create_at
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        msg
        chatchannelsID
        create_at
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const chatsByChatchannelsID = /* GraphQL */ `
  query ChatsByChatchannelsID(
    $chatchannelsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatsByChatchannelsID(
      chatchannelsID: $chatchannelsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        msg
        chatchannelsID
        create_at
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ByTime = /* GraphQL */ `
  query ByTime(
    $chatchannelsID: ID!
    $create_at: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ByTime(
      chatchannelsID: $chatchannelsID
      create_at: $create_at
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        msg
        chatchannelsID
        create_at
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChatChannels = /* GraphQL */ `
  query GetChatChannels($id: ID!) {
    getChatChannels(id: $id) {
      id
      type
      lang
      Chats {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listChatChannels = /* GraphQL */ `
  query ListChatChannels(
    $filter: ModelChatChannelsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        lang
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
