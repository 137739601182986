import GameList from './GameList';

export default function GameSlot() {
  const data = {
    gameType: "vs",
    title: "pages.games.title.slot",
    iconName: "slot"
  }

  return (
    <>
      <GameList {...data} />
    </>
  )
}