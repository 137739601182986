import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectModal, closeModal } from 'store/modules/components';
import Dialog from '@mui/material/Dialog';
import ProgressLayout from './ProgressLayout';
// import ClaimRewardLayout from './ClaimRewardLayout';
import { useTranslation } from 'react-i18next'

const tabs = [
  {
    name: 'Progress',
    id: 'progress',
    component: <ProgressLayout />
  },
  {
    name: 'Claim Reward',
    id: 'claimReward',
    // component: <ClaimRewardLayout />
  },
]

function TabsHeader(props) {
  const { t } = useTranslation();
  const { tabs, activeTab, changeTab } = props;
  return (
    <div className="tabs-wrapper two-layout">
      <div className="tabs-header">
      {tabs.map((item, index) => {
        return <button key={index}
          className={`tab-button ${activeTab === item ? `active` : ''}`}
          onClick={() => changeTab(item)} disabled={!item.component}>
            {t(`component.modal.vip.${item.id}.title`)}
          </button>
        })}
      </div>
    </div>
  )
}

function TabsBody(props) {
  const { activeTab } = props;
  return (
    <div className="content-detail-wrapper tabs-layout">
      {activeTab.component}
    </div>
  )
}
function TabsLayout() {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const changeTab = (id) => {
    setActiveTab(id);
  };
  return (
    <>
      <TabsHeader tabs={tabs} activeTab={activeTab} changeTab={changeTab} />
      <TabsBody activeTab={activeTab} />
    </>
  );
}

export default function VipModal() {
  const { t } = useTranslation();
  const { modalType, isOpen } = useSelector(selectModal);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeModal());
  };
  return (
    <>
      <Dialog
        open={modalType === "Vip" && isOpen}
        onClose={handleClose}
        className="base-dialog-wrapper content-dialog-wrapper small"
      >
        <div className="base-dialog-inner">
          <div className="base-dialog-header">
            <p className="base-dialog-header__title">{t('component.modal.vip.title')}</p>
            <button onClick={handleClose} className="close-btn"></button>
          </div>
          <div className="base-dialog-body">
            <TabsLayout />
          </div>
        </div>
      </Dialog>
    </>
  )
}