import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSearchView } from 'store/modules/components';
import SearchInput from 'components/ui/input/SearchInput'
import { getGameListAPI } from 'api/pragmatic'
import { useSnackbar } from "notistack";
import { movePath } from 'utils/movePath';
import { useNavigate } from "react-router-dom";
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog';
import LogoImg from 'assets/Logo/logo_image.png'


function SoonModal({ open, handleClose }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="base-dialog-wrapper small"
    >
      <div className="base-dialog-inner bottom-p-32 m-bottom-p-24">
        <div className="base-dialog-header">
          <button onClick={handleClose} className="close-btn"></button>
        </div>
        <div className="base-dialog-body soon">
          <div className="soon-inner">
            <img src={LogoImg} alt="ace casino logo img" />
            <p>COMING SOON</p>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default function SearchView() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { t } = useTranslation();
  const handleClose = () => {
    dispatch(setSearchView(false));
  };
  const isMobile = useSelector(state => { return state?.ui?.screen.viewType.mobile; });
  const user = useSelector(state => { return state?.user; });
  const { language, isLoggedIn } = user;
  const [keyword, setKeyword] = useState(null);
  const [list, setList] = useState([]);
  const [time, setTime] = useState(null);
  const [delay, setDelay] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const modalClose = () => {
    setModalOpen(false);
  };

  const isChange = (e) => {
    const gap = e.timeStamp - time;
    const value = e.target.value;
    setKeyword(value);
    if (!value) {
      setList([]);
      clearTimeout(delay);
      setLoading(false)
      return;
    }
    if (value.length >= 3) {
      setLoading(true)
      clearTimeout(delay);
      setDelay(setTimeout(() => {
        getGameSearch(value);
      }, 1000))

      if (time && gap >= 5000) {
        clearTimeout(delay);
        setDelay(setTimeout(() => {
          getGameSearch(value);
        }, 2000))
      }
    }

    setTime(e.timeStamp);
  }

  const searchInputStyle = {
    id: "total-search",
    placeholder: t('component.search.placeholder'),
    autoFocus: true,
    value: keyword,
    setValue: setKeyword,
    close: handleClose,
    isChange
  }

  const getGameSearch = async (value) => {
    try {
      const res = await getGameListAPI("", value);
      // console.log("res", res?.games)
      setList(res?.games)
      setLoading(false)
    }
    catch(e) {
      console.log("getGameListAPI e: ", e)
      setLoading(false)
    }
  }
  useEffect(() => {
    if (!keyword) {
      setList([]);
      return;
    }
  }, [keyword])

  const detailGameItem = (item) => {
    if (item.commingSoon) {
      setModalOpen(true);
      return;
    }
    if (!item.gameID) return;
    if (!isLoggedIn) return enqueueSnackbar({ msg: 'commonMsg.pleaseLogin', variant: "info", action: () => {closeSnackbar()} });
    const content = item.gameTypeID === "lg" ? "casino" : item.gameTypeID === "vs" ? "slot" : "";
    let pathName = `/${content}/games/${item.gameID}`;
    const gameData = { gameType: item.gameTypeID, title: content, iconName: content, gameName: item.gameName }
    movePath(nav, language, pathName, { gameData })
    handleClose()
  }

  return (
    <>
      {!isMobile ?
      <div className="search-container">
        <div className="search-container__drop" onClick={handleClose}></div>
        <div className="search-view">
          <div className="search-view__inner">
            <SearchInput {...searchInputStyle} />
            {loading ? (
              <div className="loading-wrap"><CircularProgress size={30} color="inherit" /></div>
              ) : list?.length ? (
                <div className="result">
                  <p className="result__title">{t('component.search.result')}</p>
                  <ul className="result__list">
                    {list.map((item, index) => (
                      <li onClick={() => detailGameItem(item)} key={index}><p>{item.gameName}</p></li>
                    ))}
                  </ul>
                </div>
              ) : keyword?.length ? (
                <p className="infos">{t('component.search.noResult')}</p>
              ) : (
                <p className="infos">{t('component.search.info')}</p>
              )
            }
          </div>
        </div>
      </div> : null}
      <SoonModal open={modalOpen} handleClose={modalClose} />
    </>
  )
}