import { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
// import General from './General';
// import Security from './Security';
import Preference from './Preference';
// import Redeem from './Redeem';
import Select from 'components/ui/select/Select'
import { movePath } from 'utils/movePath';
import Dialog from '@mui/material/Dialog';
import BaseButton from 'components/ui/button/BaseButton';
import { useTranslation } from 'react-i18next'

const asideMenu = [
  // {
  //   title: "pages.settings.asideMenu.general",
  //   component: <General />,
  //   id: "general",
  // },
  // {
  //   title: "pages.settings.asideMenu.security",
  //   component: <Security />,
  //   id: "security",
  // },
  {
    title: "pages.settings.asideMenu.preference",
    component: <Preference />,
    id: "preference",
  },
  // {
  //   title: "pages.settings.asideMenu.redeem",
  //   component: <Redeem />,
  //   id: "redeem",
  // },
  {
    title: "pages.settings.asideMenu.selfExclusion",
    id: "selfExclusion",
  },
 ]

 function SelfExclusionModal({ open, handleClose }) {
  const { t } = useTranslation();
  const username = useSelector(state => {
    return state?.user?.userInfo?.username;
  });

  const openEmail = () => {
    const emailAddress = 'support@acecasino.io';
    const subject = `[Self Exclusion Request]`;
    const body = `- ${t('component.modal.selfExclusion.infoList.username')}: ${username}
    ${t('component.modal.selfExclusion.infoList.date')}:
    ${t('component.modal.selfExclusion.infoList.email')}:
    ${t('component.modal.selfExclusion.infoList.firstName')}:
    ${t('component.modal.selfExclusion.infoList.lastName')}:
    ${t('component.modal.selfExclusion.infoList.explanation')}: `

    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="base-dialog-wrapper small"
    >
      <div className="base-dialog-inner bottom-p-32 m-bottom-p-24">
        <div className="base-dialog-header">
          <p className="base-dialog-header__title medium">{t('component.modal.selfExclusion.title')}</p>
          <button onClick={handleClose} className="close-btn"></button>
        </div>
        <div className="base-dialog-body selfExclusion">
          <div className="selfExclusion-inner">
            <p className="selfExclusion-inner__title" dangerouslySetInnerHTML={{ __html: t('component.modal.selfExclusion.subTitle', {email: 'support@acecasino.io'}) }}></p>
            <ul className="selfExclusion-inner__desc">
              <li><p>{t('component.modal.selfExclusion.infoList.username')}</p></li>
              <li><p>{t('component.modal.selfExclusion.infoList.date')}</p></li>
              <li><p>{t('component.modal.selfExclusion.infoList.email')}</p></li>
              <li><p>{t('component.modal.selfExclusion.infoList.firstName')}</p></li>
              <li><p>{t('component.modal.selfExclusion.infoList.lastName')}</p></li>
              <li><p>{t('component.modal.selfExclusion.infoList.explanation')}</p></li>
              <li><p>{t('component.modal.selfExclusion.end')}</p></li>
            </ul>
          </div>
          <BaseButton onClick={openEmail} color="primary" label="component.modal.selfExclusion.sendEmail" size="large" />
        </div>
      </div>
    </Dialog>
  )
 }


function SectionAsideBar({state, menu, changeView}) {
  const { t } = useTranslation();
  return (
    <aside className="section-asidebar">
      <ul className="section-asidebar-menu">
        {menu.map((item, index) => {
          return <li key={index} onClick={() => changeView(item)}
          className={`section-asidebar-menu__item ${state.id === item.id ? 'isActive' : ''}`}>
            <p>{t(item.title)}</p>
          </li>
        })}
      </ul>
    </aside>
  )
}

export default function Settings() {
  const { t } = useTranslation();
  const { section } = useParams();
  const current = asideMenu.find(item => item.id === section);
  const [state, setState] = useState(current || asideMenu[0]);
  const [modalOpen, setModalOpen] = useState(false);
  const tuchDevice = useSelector(state => { return state?.ui?.screen.tuchDevice; });
  const nav = useNavigate();
  const lang = useSelector(state => { return state?.user?.language; });
  const isInitialRender = useRef(true);

  useEffect(() => {
    const current = asideMenu.find(item => item.id === section);
    setState(current);
  }, [section]);

  useEffect(() => {
    if (!isInitialRender.current) {
      let pathName = `/settings/${state.id}`;
      movePath(nav, lang, pathName);
    } else {
      isInitialRender.current = false;
    }
  }, [state]);


  const changeView = (item) => {
    if (item.component) setState(item)
    else if (!item.component && item.id === "selfExclusion") setModalOpen(true);
  }
  const selectData = { selectList: asideMenu, id: "settings-aside", select: state, setSelect: setState, isFunction: changeView };

  const modalClose = () => {
    setModalOpen(false);
  };


  return (
    <>
      <div className="settings-wrapper section-wrapper">
        <div className="section-wrapper__header select-rows">
          <p className="settings">{t('pages.settings.title')}</p>
          {tuchDevice && <Select {...selectData} />}
        </div>
        <div className="section-inner settings-inner">
          {!tuchDevice && <SectionAsideBar state={state} setState={setState} menu={asideMenu} selectData={selectData} changeView={changeView} />}
          {asideMenu.find(item => item.id === state.id)?.component}
        </div>
      </div>
      <SelfExclusionModal open={modalOpen} handleClose={modalClose} />
    </>
  )
}