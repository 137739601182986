import { useTranslation } from 'react-i18next'
// import CopyItem from 'components/ui/CopyItem';

export default function OverviewLayout({rewardRatio}) {
  const { t } = useTranslation();

  return (
    <div className="overview-content">
      <p className="overview-content__title">{t('pages.affiliate.overview.title')}</p>
      <div className="overview-content__inner">
        <p>{t('pages.affiliate.overview.subTitle')}</p>
        <ul className="list">
          <li><p>{t('pages.affiliate.overview.infoList.1')}</p></li>
          <li><p>{t('pages.affiliate.overview.infoList.2')}</p></li>
          <li><p>{t('pages.affiliate.overview.infoList.3', {commission: rewardRatio ? rewardRatio : '-'})}</p></li>
        </ul>
        <div className="commission">
          <div className="commission__user">
            <p>{t('pages.affiliate.overview.commission')}</p>
            <div className="commission__user--box">
              <p className="commission-number">{rewardRatio ? rewardRatio : '-'}%</p>
            </div>
          </div>
          <div className="commission__contact">
            <p>{t('pages.affiliate.overview.contact')} <a className="primary-mail" href="mailto:business@acecasino.io">business@acecasino.io</a></p>
          </div>
        </div>
        {/* <div className="referral-wrapper">
          <p>{t('pages.affiliate.overview.refLink')}</p>
          <CopyItem address={key} styled="between" />
        </div> */}
        {/* <div className="link-banner-wrapper">
          <div className="link-banner-wrapper__image guide">
            <p>{t('pages.affiliate.overview.guideTitle')}</p>
          </div>
          <div className="link-banner-wrapper__image commission">
            <p>{t('pages.affiliate.overview.commissionTitle')}</p>
          </div>
        </div> */}
      </div>
    </div>
  )
}