import { useState, useEffect, useRef, cloneElement } from "react";
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Overview from './Overview';
import ManageCampaign from './ManageCampaign';
import ReferredUsers from './ReferredUsers';
import SubAffiliate from './SubAffiliate';
// import Fund from './Fund';
import Select from 'components/ui/select/Select'
import { movePath } from 'utils/movePath';
import { useTranslation } from 'react-i18next'
import { getUserCampaignListAPI, getIsChildAPI, getAffiliateCampaignRewardRatioAPI, getSubAffiliateInfoAPI } from 'api/affilate';

const asideMenu = [
  {
    title: "pages.affiliate.asideMenu.overview",
    component: <Overview />,
    id: "overview",
  },
  {
    title: "pages.affiliate.asideMenu.manageCampaign",
    component: <ManageCampaign />,
    id: "manageCampaign",
  },
  {
    title: "pages.affiliate.asideMenu.referredUsers",
    component: <ReferredUsers />,
    id: "referredUsers"
  },
  {
    title: "pages.affiliate.asideMenu.subAffiliate",
    component: <SubAffiliate />,
    id: "subAffiliate"
  },
  // {
  //   title: "pages.affiliate.asideMenu.fund",
  //   component: <Fund />,
  //   id: "fund",
  // },
]


function SectionAsideBar({state, setState, menu, campaignList, hasSubAgents}) {
  const { t } = useTranslation();
  const tuchDevice = useSelector(state => { return state?.ui?.screen.tuchDevice; });
  const filterMenu = menu.filter(obj => !((hasSubAgents && obj.id === "subAffiliate") || (hasSubAgents === null && obj.id === "subAffiliate")) && !(obj.id === "referredUsers" && !campaignList?.length))
  const selectData = { selectList: filterMenu, id: "affiliate-aside", select: state, setSelect: setState, data: campaignList };

  const changeState = (item) => {
    setState(item)
  }

  return (
    <>
      {tuchDevice ?
      <div className="section-select"><Select {...selectData} /></div> :
      <aside className="section-asidebar">
        <ul className="section-asidebar-menu">
          {filterMenu.map((item, index) => {
            return <li key={index} onClick={() => changeState(item)}
            className={`section-asidebar-menu__item ${state.id === item.id ? 'isActive' : ''}`}>
              <p>{t(item.title)}</p>
            </li>
          })}
        </ul>
      </aside>}
    </>
  )
}

export default function Affiliate() {
  const { t } = useTranslation();
  const { section } = useParams();
  const userEmail = useSelector(state => { return state?.user?.userInfo.email; });
  const current = asideMenu.find(item => item.id === section);
  const [state, setState] = useState(current || asideMenu[0]);
  const [campaignList, setCampaignList] = useState([]);
  const [rewardRatio, setRewardRatio] = useState(null);
  const [hasSubAgents, setHasSubAgents] = useState(null);
  const [isChild, setIsChild] = useState([]);
  const { component } = state;
  const nav = useNavigate();
  const lang = useSelector(state => { return state?.user?.language; });
  const isInitialRender = useRef(true);

  const getSubAffiliateInfo = async () => {
    try {
      const res = await getSubAffiliateInfoAPI(userEmail)
      setHasSubAgents(!!res)
    } catch(e) {
      console.error("getSubAffiliateInfoAPI e: ", e)
    }
  }

  const getData = () => {
    getUserCampaignList()
    getIsChild()
    getAffiliateCampaignRewardRatio()
  }

  const getUserCampaignList = async () => {
    try {
      const res = await getUserCampaignListAPI(userEmail);
      setCampaignList(res);
    } catch(e) {
      console.error("e:", e)
    }
  }

  const getIsChild = async () => {
    try {
      const res = await getIsChildAPI(userEmail);
      setIsChild(res);
    } catch(e) {
      console.error("e:", e)
    }
  }

  const getAffiliateCampaignRewardRatio = async () => {
    try {
      const res = await getAffiliateCampaignRewardRatioAPI(userEmail);
      setRewardRatio(res * 100);
    } catch(e) {
      console.error("e:", e)
    }
  }

  useEffect(() => {
    const current = asideMenu.find(item => item.id === section);
    setState(current);
  }, [section]);

  useEffect(() => {
    if (!isInitialRender.current) {
      let pathName = `/affiliate/${state.id}`;
      movePath(nav, lang, pathName);
    } else {
      isInitialRender.current = false;
      getSubAffiliateInfo()
      getData()
    }
  }, [state]);

  const propData = { isChild, campaignList, getUserCampaignList, rewardRatio, hasSubAgents };

  return (
    <div className="affiliate-wrapper section-wrapper">
      <div className="section-wrapper__header">
        <p className="affiliate">{t('pages.affiliate.title')}</p>
      </div>
      <div className="section-inner affiliate-inner">
        <SectionAsideBar section={section} state={state} setState={setState} menu={asideMenu} campaignList={campaignList} hasSubAgents={hasSubAgents} />
        {cloneElement(component, propData)}
      </div>
    </div>
  )
}