import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentSymbol, setUserInfo } from 'store/modules/user';
import { setSidebarView, setIsExpanded } from 'store/modules/ui';
import { setSearchView } from 'store/modules/components';
import CurrencyTooltip from 'components/tooltip/CurrencyTooltip'
import { Button } from '@mui/base/Button'
import IconButton from 'components/ui/button/IconButton';
import BaseButton from 'components/ui/button/BaseButton';
import { movePath } from 'utils/movePath';
import { openModal } from 'store/modules/components';
import useAuth from 'hooks/useAuth';
import { toNumberFormat } from 'utils/toNumberFormat';
import { useTranslation } from 'react-i18next'
import SymbolImg from 'components/item/SymbolImg';
import useUserInfoUpdate from 'hooks/useUserInfoUpdate'
// import Badge from '@mui/material/Badge';

function Logo({sloganShow}) {
  const nav = useNavigate();
  const user = useSelector(state => {
    return state?.user;
  });
  const { isLoggedIn, language: lang } = user;
  return (
    <div className="x-start">
      <div className="logo-wrapper" onClick={()=> movePath(nav, lang)}>
        <div className={`logo ${isLoggedIn && sloganShow ? 'in-logo' : 'out-logo'}`}></div>
        {!sloganShow && <p className="slogan">WORLD CLASS USDT CASINO</p>}
      </div>
    </div>
  )
}
function UserProfile() {
  const [isProfileTooltip, setIsProfileTooltip] = useState(false);
  const language = useSelector(state => { return state?.user?.language; });
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { t } = useTranslation();
  const { logout } = useAuth();


  const handleShowTooltip = () => {
    setIsProfileTooltip(!isProfileTooltip);
  };
  const handleOutsideClick = () => {
    setIsProfileTooltip(false);
  };
  const useOutsideClick = (callback) => {
    const ref = useRef(null);
    useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, [callback, ref]);

    return ref;
  };
  const ref = useOutsideClick(handleOutsideClick);

  const list = [
    {
      name: 'Wallet',
      component: 'Wallet',
      icon: 'wallet',
    },
    // {
    //   name: 'Vault',
    //   component: 'Vault',
    //   icon: 'vault',
    // },
    // {
    //   name: 'VIP Club',
    //   component: 'Vip',
    //   icon: 'vip',
    // },
    // {
    //   name: 'Affiliate',
    //   pathName: '/affiliate/overview',
    //   icon: 'affiliate',
    // },
    {
      name: 'Statistics',
      pathName: '/statistics',
      icon: 'statistics',
    },
    {
      name: 'History',
      pathName: '/history',
      icon: 'history',
    },
    {
      name: 'Redeem Code',
      component: 'Redeem',
      icon: 'redeemCode',
    },
    {
      name: 'Promotions',
      pathName: '/promotion',
      icon: 'promotion',
    },
    {
      name: 'Settings',
      pathName: '/settings/preference',
      icon: 'settings',
    },
    {
      name: 'Logout',
      function: () => { logout() },
      icon: 'logout',
    },
  ]

  const handlerPath = (item) => {
    if (item.component) {
      dispatch(openModal({modalType: item.component}));
    } else if(item.function) {
      item.function()
    } else {
      movePath(nav, language, item.pathName)
    }
    handleOutsideClick()
  }

  return (
    <div ref={ref} className="user-profile-wrapper">
      <Button onClick={handleShowTooltip} className="icon-r profile"></Button>
      {isProfileTooltip &&
      <div className="p-tooltip">
        <div className="p-tooltip__inner">
          {list.map((item, index) => {
            return <div key={index} onClick={()=> handlerPath(item)} className="profile-item">
              <div className={`icon-r ${item.icon}`}></div>
              <p>{t(`gnb.${item.icon}`)}</p>
            </div>
          })}
        </div>
      </div>}
    </div>
  )
}

let init = false;

function UserCurrentBalance() {
  const user = useSelector(state => { return state?.user; });
  const { currentSymbol, userInfo } = user;
  const [balanceList, setBalanceList] = useState([]);
  const [isCurrencyTooltip, setIsCurrencyTooltip] = useState(false);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { updateUserBalance, sortUserBalance } = useUserInfoUpdate();

  useEffect(() => {
    const default_symbol = "USDT(Ethereum)";
    const current_symbol_str = localStorage.getItem('current_symbol');
    const current_symbol = current_symbol_str && current_symbol_str === 'undefined' ? null : current_symbol_str && current_symbol_str !== 'undefined' ? JSON.parse(current_symbol_str) : null;

    let result;
    if (current_symbol && current_symbol?.symbol) {
      result = userInfo?.balance?.find(item => item.symbol === current_symbol.symbol);
      if (!result) {
        result = userInfo?.balance?.find(item => item.symbol === default_symbol);
      }
    } else {
      result = userInfo?.balance?.find(item => item.symbol === default_symbol);
    }
    dispatch(setCurrentSymbol(result));

    const list = sortUserBalance('total');
    setBalanceList(list)
  }, [userInfo?.balance])

  useEffect(() => {
    // router path가 변경될때나 클레임, 출금시에 balance update가 된다
    if (!init) {
      init = true;
      return;
    }
    updateUserBalance()
  }, [pathname])

  useEffect(() => {
    if (isCurrencyTooltip) {
      updateUserBalance()
    }
  }, [isCurrencyTooltip])

  const handleShowTooltip = () => {
    setIsCurrencyTooltip(!isCurrencyTooltip);
  };
  const handleOutsideClick = () => {
    setIsCurrencyTooltip(false);
  };
  const useOutsideClick = (callback) => {
    const ref = useRef(null);
    useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, [callback, ref]);

    return ref;
  };
  const ref = useOutsideClick(handleOutsideClick);

  return (
    <div ref={ref} className="current-balance-wrapper">
      {currentSymbol?.symbol && <div onClick={handleShowTooltip} className="current-balance-wrapper__inner">
        <p className="amount">{currentSymbol?.total ? toNumberFormat(currentSymbol?.total, 8) : '0.00000000'}</p>
        <SymbolImg className="currency" symbol={currentSymbol?.symbol} />
      </div>}
      {isCurrencyTooltip && <CurrencyTooltip UserCurrentList={balanceList} close={setIsCurrencyTooltip} />}
    </div>
  )
}

function SearchView() {
  const dispatch = useDispatch();
  const showSearchView = () => {
    dispatch(setSearchView(true));
  };
  return (
    <button onClick={showSearchView} className="search-btn"></button>
  )
}

function LoggedInLayout({sloganShow}) { // 로그인 상태일때
  const dispatch = useDispatch();
  const sidebarView = useSelector(state => { return state?.ui?.sidebarView; });
  const isExpanded = useSelector(state => { return state?.ui?.isExpanded; });
  const isMobile = useSelector(state => { return state?.ui?.screen.viewType.mobile; });
  const openWalletModal = () => { dispatch(openModal({modalType: "Wallet"})); };
  const toggleOptionSidebar = (view) => {
    if (isMobile && isExpanded) {
      dispatch(setIsExpanded(false));
    }
    view !== sidebarView ? dispatch(setSidebarView(view)) : dispatch(setSidebarView('hidden'));
  };
  return (
    <div className="logged-layout logged-in">
      <SearchView />
      {isMobile && <Logo sloganShow={sloganShow} />}
      <div className="wallet-wrapeer">
        <UserCurrentBalance />
        <IconButton onClick={()=> openWalletModal()} color="primary" iconName="wallet" label="commonDesc.wallet" size="small" />
      </div>
      <div className="user-support">
        <div className="user-support__inner">
          <UserProfile />
        </div>
        {/* <Badge className="noti-badge" badgeContent={0} max={100}> */}
        {/* <button onClick={()=> toggleOptionSidebar('noti')} className={`icon-r notifications ${true ? "hasNoti" : ""}`}></button> */}
        {/* </Badge> */}
        <button onClick={()=> toggleOptionSidebar('chat')} className="icon-r chat"></button>
      </div>
    </div>
  )
}
function LoggedOutLayout({sloganShow}) { // 로그아웃 상태일때
  const dispatch = useDispatch();
  const isMobile = useSelector(state => { return state?.ui?.screen.viewType.mobile; });
  const openSignInModal = () => {dispatch(openModal({modalType: "SignIn"}));};
  const openRegisterModal = () => {dispatch(openModal({modalType: "Register"}));};
  const { t } = useTranslation();

  return (
    <div className="logged-layout logged-out">
      <SearchView />
      {isMobile && <Logo sloganShow={sloganShow} />}
      <div className="user-sign">
        <BaseButton onClick={openSignInModal} type="text" label={t('commonDesc.signIn')} size="small" />
        <BaseButton onClick={openRegisterModal} color="primary" label={t('commonDesc.register')} size="small" />
      </div>
    </div>
  )
}

function Header() {
  const user = useSelector(state => {
    return state?.user;
  });
  const { isLoggedIn } = user;
  const isMobile = useSelector(state => { return state?.ui?.screen.viewType.mobile; });

  const bannerRef = useRef(null);
  const [bannerRefSize, setBannerRefSize] = useState(null);

  useEffect(() => {
    const handleResize = (entries) => {
      const newWidth = entries[0].contentRect.width;
      setBannerRefSize(newWidth);
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (bannerRef.current) {
      resizeObserver.observe(bannerRef.current);
      setBannerRefSize(bannerRef.current.offsetWidth);
    }

    return () => resizeObserver.disconnect();
  }, [bannerRef]);

  const sloganShow = bannerRefSize <= 768;

  return (
    <header ref={bannerRef} className="navigation" style={sloganShow ? { height: '90px', paddingTop: '10px', justifyContent: 'space-between' } : {}}>
      <div className="navigation__inner">
        {!isMobile && <Logo sloganShow={sloganShow} />}
        { isLoggedIn ? <LoggedInLayout sloganShow={sloganShow} /> : <LoggedOutLayout sloganShow={sloganShow} /> }
      </div>
      {sloganShow && <div className="mobile-header-line">
        <div className="mobile-header-line__inner">
          <p className="slogan">WORLD CLASS USDT CASINO</p>
        </div>
      </div>}
    </header>
  );
}

export default Header;
